import { http } from '../../../api/http-common';

const searchPersonal = async (params) => {
	try {
		const { data } = await http.get('programation', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const findCourses = async (params) => {
	try {
		const { data } = await http.get('course', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const findOnePersonal = async (id) => {
	try {
		const { data } = await http.get(`programation/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const findCoursesUser = async (params) => {
	try {
		const { data } = await http.get('course/personal', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveCourseUser = async (payload) => {
	try {
		const { data } = await http.post('course/user', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveCourseCharge = async (payload) => {
	try {
		const { data } = await http.post('course/charge', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveCourseUserRegister = async (payload) => {
	try {
		const { data } = await http.post('course/user/register', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	searchPersonal,
	findOnePersonal,
	findCoursesUser,
	findCourses,
	saveCourseUser,
	saveCourseCharge,
	saveCourseUserRegister
};
