import { http } from '@/store/api/http-common';

const saveMaintenance = async (params) => {
    try {
        const { data } = await http.post('asset-maintenances', params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};


const getAssetMaintenanceById = async (id) => {
    try {
        const { data } = await http.get(`asset-maintenances/${id}`);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getAssetMaintenances = async (params) => {
    try {
        const { data } = await http.get('asset-maintenances', { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const releaseAsset = async (id, params) => {
    try {
        const { data } = await http.put(`asset-maintenances/release-asset/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

export {
    saveMaintenance,
    getAssetMaintenanceById,
    getAssetMaintenances,
    releaseAsset
};
