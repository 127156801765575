<template>
	<div>
		<v-tooltip v-if="!isAllowed" bottom>
			<template v-slot:activator="{ on, attrs }">
				<span v-bind="attrs" v-on="on">
					<slot :isDisabled="!isAllowed"></slot>
				</span>
			</template>
			<span>Sin Permiso</span>
		</v-tooltip>
		<slot v-else :isDisabled="!isAllowed"></slot>
	</div>
</template>
<script>
export default {
	name: 'ValidateActivity',
	props: {
		module: String,
		page: String,
		activity: String,
	},
	data: () => ({}),
	computed: {
		isAllowed: function () {
			const activities = this.$store.state.auth.activities || [];
			const found = activities.some(
				(activity) =>
					activity.module_name == this.module &&
					activity.page_name == this.page &&
					activity.activity_name == this.activity
			);
			return found;
		},
	},
	methods: {},
};
</script>
