import * as ticketService from './service/ticket.service';

const state = {
    ticket: null,
    tickets: [],
    ticketsPagination: {
        page: 1,
        limit: 10,
        totalItems: 0,
        totalPages: 0,
    },
    //
    unqualified: false,
    //
    allTickets: [],
    loadingTicket: false,
    filtersTicket: {
        ticket_number: null,
        status: null,
        area: null
    }
};

const mutations = {
    SET_FILTER_TICKET(state, data) {
        state.filtersTicket = data;
    },

    SET_LOADING_TICKET(state, data) {
        state.loadingTicket = data;
    },

    SET_TICKET(state, ticket) {
        state.ticket = ticket;
    },

    SET_ALL_TICKETS(state, data) {
        state.allTickets = data;
    },

    SET_TICKETS(state, payload) {
        state.tickets = payload;
    },

    SET_TICKET_PAGINATION(state, payload) {
        state.ticketsPagination = { ...state.ticketsPagination, ...payload };
    },

    SET_PAGE(state, page) {
        state.tickets.page = page;
    },

    SET_LIMIT(state, limit) {
        state.tickets.limit = limit;
    },

    SET_UNQUALIFIED(state, value) {
        state.unqualified = value;
    },
    // UPDATE_TICKET(state, updateTicket) {
    //     const index = state.tickets.findIndex((ticket) => ticket.id === updateTicket.id);
    //     if (index !== -1) {
    //         state.tickets.splice(index, 1, updateTicket);
    //     }
    // },

    // DELETE_TICKET(state, ticketId) {
    //     const index = state.tickets.findIndex((ticket) => ticket.id === ticketId);
    //     if (index !== -1) {
    //         state.tickets.splice(index, 1);
    //     }
    // },
};

const actions = {
    async createTicket(__, data) {
        try {
            const response = await ticketService.saveTicket(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async confirmUser(__, data) {
        try {
            const response = await ticketService.addUserConfirm(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async addUser(__, data) {
        try {
            const response = await ticketService.addUserTicket(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async getTicketById({ commit }, id) {
        try {
            const response = await ticketService.getTicketById(id);
            commit('SET_TICKET', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listAllTickets({ commit }, data) {
        try {
            const response = await ticketService.getTicket(data);
            commit('SET_ALL_TICKETS', response.tickets);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listTicket({ commit }, data) {
        try {
            commit('SET_TICKETS', []);
            const response = await ticketService.getTicket(data);

            //
            commit('SET_UNQUALIFIED', response?.unqualified || false);
            //
            const pagination = {
                page: response?.ticketsPagination.page,
                limit: response?.ticketsPagination.limit,
                totalItems: response?.ticketsPagination.totalItems,
                totalPages: response?.ticketsPagination.totalPages,
            };
            commit('SET_TICKETS', response?.tickets || []);
            commit('SET_TICKET_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listTicketForUsers({ commit }, data) {
        try {
            commit('SET_TICKETS', []);
            const response = await ticketService.getTicketsForUsers(data);
            const pagination = {
                page: response?.ticketsPagination.page,
                limit: response?.ticketsPagination.limit,
                totalItems: response?.ticketsPagination.totalItems,
                totalPages: response?.ticketsPagination.totalPages,
            };
            commit('SET_UNQUALIFIED', response?.unqualified || false);
            commit('SET_TICKETS', response?.tickets || []);
            commit('SET_TICKET_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listTicketForResponsibles({ commit }, data) {
        try {
            commit('SET_TICKETS', []);
            const response = await ticketService.getTicketsForResponsibles(data);
            const pagination = {
                page: response?.ticketsPagination.page,
                limit: response?.ticketsPagination.limit,
                totalItems: response?.ticketsPagination.totalItems,
                totalPages: response?.ticketsPagination.totalPages,
            };
            commit('SET_UNQUALIFIED', response?.unqualified || false);
            commit('SET_TICKETS', response?.tickets || []);
            commit('SET_TICKET_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async closeTicket({ commit }, id) {
        try {
            const response = await ticketService.closeTicket(id);
            commit('SET_TICKET', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async ticketConfirm({ commit }, id) {
        try {
         
            const response = await ticketService.confirmTicket(id);
          
            commit('SET_TICKET', response);
            return { ok: true, response };
        } catch (error) {
         
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async reassignResponsible(__, { id, data }) {
        try {
            const response = await ticketService.reassignResponsible(id, data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    // async updateTicket({ commit }, { id, data }) {
    //     try {
    //         const response = await ticketService.updateTicket(id, data);
    //         commit('UPDATE_TICKET', response);
    //         return { ok: true, response };
    //     } catch (error) {
    //         return { ok: false, error: error?.response || 'Error en el servicio' };
    //     }
    // },

    // async deleteTicket(__, id) {
    //     try {
    //         const response = await ticketService.deleteTicket(id);
    //         return { ok: true, response };
    //     } catch (error) {
    //         return { ok: false, error: error?.response || 'Error en el servicio' };
    //     }
    // },

    cleanTicket({ commit }) {
        commit('SET_TICKET', null);
    },

    cleanTickets({ commit }) {
        commit('SET_TICKETS', []);
        commit('SET_TICKET_PAGINATION', {
            page: 0,
            limit: 0,
            totalItems: 0,
            totalPages: 0,
        });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};