import * as spareHistoryService from "./service/spare-history.service";

const state = {
    spareHistory: null,
    sparesHistory: [],
    spareHistoryPagination: {
      page: 1,
      limit: 10,
      totalItems: 0,
      totalPages: 0,
    },
    allSparesHistory: [],
    loadingSpareHistory: false,
    filtersSpareHistory: {
      spareHistory_type: null,
    },
  };
  
  const mutations = {
    SET_FILTER_SPARE_HISTORY(state, data) {
      state.filtersSpareHistory = data;
    },
  
    SET_LOADING_SPARE_HISTORY(state, data) {
      state.loadingSpareHistory = data;
    },
  
    //
    SET_SPARE_HISTORY(state, asset) {
      state.spareHistory = asset;
    },
    //
  
    SET_ALL_SPARES_HISTORY(state, data) {
      state.allSparesHistory = data;
    },
  
    SET_SPARES_HISTORY(state, payload) {
      state.sparesHistory = payload;
    },
  
    SET_SPARE_HISTORY_PAGINATION(state, payload) {
      state.spareHistoryPagination = {
        ...state.spareHistoryPagination,
        ...payload,
      };
    },
  
    SET_PAGE_SPARE_HISTORY(state, page) {
      state.sparesHistory.page = page;
    },
  
    SET_LIMIT_SPARE_HISTORY(state, limit) {
      state.sparesHistory.limit = limit;
    },
  
    UPDATE_SPARE_HISTORY(state, updateSpareHistory) {
      const index = state.sparesHistory.findIndex(
        (spareHistory) => spareHistory.id === updateSpareHistory.id
      );
      if (index !== -1) {
        state.sparesHistory.splice(index, 1, updateSpareHistory);
      }
    },
  
    DELETE_SPARE_HISTORY(state, spareHistoryId) {
      const index = state.sparesHistory.findIndex(
        (spareHistory) => spareHistory.id === spareHistoryId
      );
      if (index !== -1) {
        state.sparesHistory.splice(index, 1);
      }
    },
  };
  
  const actions = {
    async assignAsset(__, data) {
      try {
        const response = await spareHistoryService.assignAsset(data);
        return { ok: true, response };
      } catch (error) {
        return { ok: false, error: error?.response || "Error en el servicio" };
      }
    },
  
    async listSpareHistory({ commit }, data) {
      try {
        commit("SET_SPARES_HISTORY", []);
        const response = await spareHistoryService.getSpareHistory(data);
        const pagination = {
          page: response?.spareHistoryPagination.page,
          limit: response?.spareHistoryPagination.limit,
          totalItems: response?.spareHistoryPagination.totalItems,
          totalPages: response?.spareHistoryPagination.totalPages,
        };
        commit("SET_SPARES_HISTORY", response?.spareHistory || []);
        commit("SET_SPARE_HISTORY_PAGINATION", pagination || {});
        return { ok: true, response };
      } catch (error) {
        return { ok: false, error: error?.response || "Error en el servicio" };
      }
    },
    cleanAssetsHistory({ commit }) {
      commit("SET_SPARES_HISTORY", []);
      commit("SET_SPARE_HISTORY_PAGINATION", {
        page: 0,
        limit: 0,
        totalItems: 0,
        totalPages: 0,
      });
    },
  };
  
  const getters = {};
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  