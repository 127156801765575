<template>
	<v-app>
		<router-view></router-view>
		<snack-notification />
	</v-app>
</template>

<script>
import SnackNotification from '../components/global/SnackNotification.vue';
export default {
	name: 'DefaultLayout',

	data: () => ({}),
	components: { SnackNotification },
};
</script>
