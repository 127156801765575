import { http } from "@/store/api/http-common";

const saveAssetType = async (params) => {
  try {
    const { data } = await http.post("asset-types", params);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getAssetType = async (params) => {
  try {
    const { data } = await http.get("asset-types", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateAssetType = async (id,params) => {
  try {
    const { data } = await http.put(`asset-types/${id}`, params);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteAssetType = async (id) => {
  try {
    const { data } = await http.delete(`asset-types/${id}`);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { saveAssetType, getAssetType, updateAssetType, deleteAssetType };
