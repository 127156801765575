import { http } from '@/store/api/http-common';

const saveAssetAssign = async (params) => {
    try {
        const { data } = await http.post('asset-assign/assign-user', params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const saveUserSigner = async (params) => {
    try {
        const { data } = await http.post('asset-assign/add-signer', params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getAssetAssignmentById = async (id) => {
    try {
        const { data } = await http.get(`asset-assign/${id}`);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

// const getAssetAssignmentCertificateById = async (id) => {
// 	try {
// 		const { data } = await http.get(`asset-assign/download-certificate/${id}`);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

const getAssetAssignmentCertificateById = async (id, userTimeZone) => {
    try {
        const response = await http.patch(`asset-assign/download-certificate/${id}`, { userTimeZone }, {
            responseType: 'blob'
        });
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getCertificateReturnAssetById = async (id, userTimeZone) => {
    try {
        const { data } = await http.patch(`asset-assign/return-asset/download-certificate/${id}`, { userTimeZone }, {
            responseType: 'blob'
        });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getAssetAssignments = async (params) => {
    try {
        const { data } = await http.get('asset-assign', { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const returnAsset = async (id, params) => {
    try {
        const { data } = await http.post(`asset-assign/return-asset/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const cancelPreassignment = async (id, params) => {
    try {
        const { data } = await http.put(`asset-assign/cancel-assignment/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const declinePreassignment = async (id, params) => {
    try {
        const { data } = await http.put(`asset-assign/decline-assignment/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const confirmPreassignment = async (id, params) => {
    try {
        const { data } = await http.put(`asset-assign/confirm-assignment/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const confirmReturn = async (id, params) => {
    try {
        const { data } = await http.put(`asset-assign/confirm-return/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const notificationManual = async (id, params) => {
    try {
        const { data } = await http.put(`asset-assign/notification-manual/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

export {
    saveAssetAssign,
    getAssetAssignments,
    returnAsset,
    cancelPreassignment,
    declinePreassignment,
    confirmPreassignment,
    confirmReturn,
    getAssetAssignmentById,
    getAssetAssignmentCertificateById,
    getCertificateReturnAssetById,
    notificationManual,
    saveUserSigner
}