import { http } from "@/store/api/http-common";

const getAssetUnsubscribe = async (params) => {
  try {
    const { data } = await http.get("asset-unsubscribe", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getAssetUnsubscribeById = async (id) => {
  try {
    const { data } = await http.get(`asset-unsubscribe/${id}`);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getCertificateAssetUnsubscribeById = async (id) => {
  try {
      const { data } = await http.get(`asset-unsubscribe/unsubscribe-asset/download-certificate/${id}`, {
          responseType: 'blob'
      });
      return data || null;
  } catch (error) {
      return Promise.reject(error);
  }
};

export { getAssetUnsubscribe, getAssetUnsubscribeById,getCertificateAssetUnsubscribeById };
