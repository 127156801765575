import { http } from '../../../api/http-common';

const login = async (params) => {
	try {
		const { data } = await http.post('auth/login', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getProfile = async () => {
	try {
		const { data } = await http.get('auth/profile', {
			params: { app_id: parseInt(process.env.VUE_APP_ID) },
		});
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const passwordRecovery = async (params) => {
	try {
		const { data } = await http.post('auth/password-recovery', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const passwordUpdateRecovery = async (params) => {
	try {
		const { data } = await http.post('auth/password-update-recovery', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const passwordRecoveryToken = async (params) => {
	try {
		const { data } = await http.get(
			`auth/password-recovery/validate/${params?.token}?application_id=${params?.application_id}`
		);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	login,
	getProfile,
	passwordRecovery,
	passwordUpdateRecovery,
	passwordRecoveryToken,
};
