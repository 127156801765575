import * as assetMaintenanceService from './service/asset-maintenance.service';

const state = {
    assetMaintenance: null,
    assetsMaintenance: [],
    assetMaintenancePagination: {
        page: 1,
        limit: 10,
        totalItems: 0,
        totalPages: 0,
    },
    allAssetsMaintenance: [],
    loadingAssetMaintenance: false,
    // filtersSpareAssign: {
    // 	spareUnsubscribe_type: null,
    // },
};

const mutations = {
    SET_ASSET_MAINTENANCE(state, assetMaintenance) {
        state.assetMaintenance = assetMaintenance;
    },

    SET_ALL_ASSETS_MAINTENANCE(state, data) {
        state.allAssetsMaintenance = data;
    },

    SET_ASSETS_MAINTENANCE(state, payload) {
        state.assetsMaintenance = payload;
    },

    SET_ASSET_MAINTENANCE_PAGINATION(state, payload) {
        state.assetMaintenancePagination = {
            ...state.assetMaintenancePagination,
            ...payload,
        };
    },

    UPDATE_ASSET_MAINTENANCE(state, updateAssetMaintenance) {
        const index = state.assetsMaintenance.findIndex((assetMaintenance) => assetMaintenance.id === updateAssetMaintenance.id);
        if (index !== -1) {
            state.assetsMaintenance.splice(index, 1, updateAssetMaintenance);
        }
    },
}

const actions = {
    async createAssetMaintenance(__, { data }) {
        try {
            const response = await assetMaintenanceService.saveMaintenance(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    async getAssetMaintenanceById({ commit }, id) {
        try {
            const response = await assetMaintenanceService.getAssetMaintenanceById(id);
            commit('SET_ASSET_MAINTENANCE', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listAllAssetsMaintenance({ commit }, data) {
        try {
            const response = await assetMaintenanceService.getAssetMaintenances(data);
            commit('SET_ALL_ASSETS_MAINTENANCE', response.assetMaintenance);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listMaintenances({ commit }, data) {
        try {
            commit('SET_ALL_ASSETS_MAINTENANCE', []);
            const response = await assetMaintenanceService.getAssetMaintenances(data);
            const pagination = {
                page: response?.assetMaintenance.page,
                limit: response?.assetMaintenance.limit,
                totalItems: response?.assetMaintenance.totalItems,
                totalPages: response?.assetMaintenance.totalPages,
            };
            commit('SET_ALL_ASSETS_MAINTENANCE', response?.spareUnsubscribes || []);
            commit('SET_ASSET_MAINTENANCE_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async assetRelease({ commit }, { id, data }) {
		try {
			const response = await assetMaintenanceService.releaseAsset(id, data);
			commit('UPDATE_ASSET_MAINTENANCE', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

    cleanAssetMaintenance({ commit }) {
		commit('SET_ASSETS_MAINTENANCE', []);
        commit('SET_ASSET_MAINTENANCE', null);
		commit('SET_ASSET_MAINTENANCE_PAGINATION', {
			page: 0,
			limit: 0,
			totalItems: 0,
			totalPages: 0,
		});
	},
}


const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
