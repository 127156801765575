import * as courseService from './service/course.service';
import { urlApi } from '../../api/config';

const state = {
	user: null,
	users: [],
	users_pagination: null,
	courses_user_pagination: null,
	courses_pagination: null,
	importCourses: [],
	coursesUser: [],
	courses: [],
	coursesSaved: [],
	format: {
		charge: `${urlApi}/course/format/download/charge`,
		user: `${urlApi}/course/format/download/user`
	}
};

const mutations = {
	SET_USER(state, payload) {
		state.user = payload;
	},

	SET_USERS(state, payload) {
		state.users = payload;
	},

	SET_COURSES(state, payload) {
		state.courses = payload;
	},

	SET_COURSES_PAGINATION(state, payload) {
		state.courses_pagination = payload;
	},

	SET_USERS_PAGINATION(state, payload) {
		state.users_pagination = payload;
	},

	SET_IMPORT_COURSES(state, payload) {
		state.importCourses = payload;
	},

	SET_COURSES_USER(state, payload) {
		state.coursesUser = payload;
	},

	SET_COURSES_USER_PAGINATION(state, payload) {
		state.courses_user_pagination = payload;
	},

	SET_COURSES_SAVED(state, payload) {
		state.coursesSaved = payload;
	},

};

const actions = {

	async searchPersonal({ commit }, data) {
		try {
			const response = await courseService.searchPersonal(data);
			commit('SET_USERS', response?.users || []);
			commit('SET_USERS_PAGINATION', response?.pagination || null);
			return { ok: true, response: response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async findOnePersonal({ commit }, id) {
		try {
			const response = await courseService.findOnePersonal(id);
			commit('SET_USER', response?.data || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},
	
	async findCoursesUser({ commit }, data) {
		try {
			const response = await courseService.findCoursesUser(data);
			commit('SET_COURSES_USER', response?.courses);
			commit('SET_COURSES_USER_PAGINATION', response?.pagination || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async findCourses({ commit }, data) {
		try {
			const response = await courseService.findCourses(data);
			commit('SET_COURSES', response?.courses || []);
			commit('SET_COURSES_PAGINATION', response?.pagination || null);
			return { ok: true, response: response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async saveCourseUser(context, data) {
		try {
			const response = await courseService.saveCourseUser(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async saveCourseCharge(context, data) {
		try {
			const response = await courseService.saveCourseCharge(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async saveCourseUserRegister(context, data) {
		try {
			const response = await courseService.saveCourseUserRegister(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	setImportUsers({ commit }, data){
		commit('SET_IMPORT_COURSES', data);
	},

	setCoursesSaved({ commit}, data){
		commit('SET_COURSES_SAVED', data);
	},

	cleanImportUsers({ commit }){
		commit('SET_IMPORT_COURSES', []);
	},

	cleanUsers({ commit }) {
		commit('SET_USERS', []);
		commit('SET_USERS_PAGINATION', null);
	},

	cleanCoursesUser({ commit }) {
		commit('SET_COURSES_USER', []);
		commit('SET_COURSES_USER_PAGINATION', null);
	},
	
	cleanUser({ commit }) {
		commit('SET_USER', null);
	},

	cleanCourses({ commit }) {
		commit('SET_COURSES', []);
		commit('SET_COURSES_PAGINATION', null);
	},

	cleanCoursesSaved({ commit }) {
		commit('SET_COURSES_SAVED', []);
	},
};

const getters = {
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
