import * as spareTypeService from "./service/spare-type.service";

const state = {
  spareType: null,
  spareTypes: [],
  spareTypesPagination: {
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
  },
  allSpareTypes: [],
};

const mutations = {
  SET_ALL_SPARE_TYPE(state, data) {
    state.allSpareTypes = data;
  },

  SET_SPARE_TYPES(state, payload) {
    state.spareTypes = payload;
  },

  SET_SPARE_TYPE_PAGINATION(state, payload) {
    state.spareTypesPagination = { ...state.spareTypesPagination, ...payload };
  },

  SET_PAGE(state, page) {
    state.spareTypes.page = page;
  },

  SET_LIMIT(state, limit) {
    state.spareTypes.limit = limit;
  },

  UPDATE_SPARE_TYPE(state, updateSpareType) {
    const index = state.spareTypes.findIndex(
      (spareType) => spareType.id === updateSpareType.id
    );
    if (index !== -1) {
      state.spareTypes.splice(index, 1, updateSpareType);
    }
  },

  DELETE_SPARE_TYPE(state, spareTypeId) {
    const index = state.spareTypes.findIndex((spareType) => spareType.id === spareTypeId);
    if (index !== -1) {
      state.spareTypes.splice(index, 1);
    }
  },
};

const actions = {
  async createSpareType(__, data) {
    try {
      const response = await spareTypeService.saveSpareType(data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listAllSpareTypes({ commit }, data) {
    try {
      const response = await spareTypeService.getSpareType(data);
      commit("SET_ALL_SPARE_TYPE", response.spareTypes);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listSpareType({ commit }, data) {
    try {
      commit("SET_SPARE_TYPES", []);
      const response = await spareTypeService.getSpareType(data);
      const pagination = {
        page: response?.spareTypesPagination.page,
        limit: response?.spareTypesPagination.limit,
        totalItems: response?.spareTypesPagination.totalItems,
        totalPages: response?.spareTypesPagination.totalPages,
      };
      commit("SET_SPARE_TYPES", response?.spareTypes || []);
      commit("SET_SPARE_TYPE_PAGINATION", pagination || {});
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async updateSpareType({ commit }, { id, data }) {
    try {
      const response = await spareTypeService.updateSpareType(id, data);
      commit("UPDATE_SPARE_TYPE", response);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async deleteSpareType(__, id) {
    try {
      const response = await spareTypeService.deleteSpareType(id);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },
  /**/

  cleanUser({ commit }) {
    commit("SET_USER", null);
  },

  cleanSpareType({ commit }) {
    commit("SET_ALL_SPARE_TYPE", []);
  },

  // cleanAssets({ commit }) {
  //   commit("SET_ASSETS", []);
  //   commit("SET_ASSETS_PAGINATION", {
  //     page: 0,
  //     limit: 0,
  //     totalItems: 0,
  //     totalPages: 0,
  //   });
  // },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
