import * as assetService from './service/asset.service';

const state = {
	asset: null,
	assets: [],
	assetsPagination: {
		page: 1,
		limit: 10,
		totalItems: 0,
		totalPages: 0,
	},
	allAssets: [],
	loadingAsset: false,
	filtersAsset: {
		asset_type: null,
		status: null,
	},
	sparesByAsset: [],
};

const mutations = {
	SET_FILTER_ASSET(state, data) {
		state.filtersAsset = data;
	},

	SET_LOADING_ASSET(state, data) {
		state.loadingAsset = data;
	},

	//
	SET_ASSET(state, asset) {
		state.asset = asset;
	},

	SET_SPARES_BY_ASSET(state, spares) {
		state.sparesByAsset = spares;
	},
	//

	SET_ALL_ASSETS(state, data) {
		state.allAssets = data;
	},

	SET_ASSETS(state, payload) {
		state.assets = payload;
	},

	SET_ASSET_PAGINATION(state, payload) {
		state.assetsPagination = { ...state.assetsPagination, ...payload };
	},

	SET_PAGE(state, page) {
		state.assets.page = page;
	},

	SET_LIMIT(state, limit) {
		state.assets.limit = limit;
	},

	UPDATE_ASSET(state, updateAsset) {
		const index = state.assets.findIndex((asset) => asset.id === updateAsset.id);
		if (index !== -1) {
			state.assets.splice(index, 1, updateAsset);
		}
	},

	DELETE_ASSET(state, assetId) {
		const index = state.assets.findIndex((asset) => asset.id === assetId);
		if (index !== -1) {
			state.assets.splice(index, 1);
		}
	},
};

const actions = {
	async createAsset(__, data) {
		try {
			const response = await assetService.saveAsset(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	//
	async createAssetMultiple(__, data) {
		try {
			const response = await assetService.saveAssetMultiple(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	// async getAssetById({ commit }, id) {
	// 	try {
	// 		const response = await assetService.getAssetById(id);
	// 		commit('SET_ASSET', response);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	async getAssetById({ commit }, { id, relations }) {
		try {
			const response = await assetService.getAssetById(id, relations);
			commit('SET_ASSET', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async cancelAsset({ commit }, { id, data }) {
		try {
			const response = await assetService.cancelAsset(id, data);
			commit('UPDATE_ASSET', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async reestablishAsset({ commit }, id) {
		try {
			const response = await assetService.reestablishAsset(id);
			commit('UPDATE_ASSET', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getSparesByAsset({ commit }, id) {
		try {
			const response = await assetService.getSparesByAsset(id);
			commit('SET_SPARES_BY_ASSET', response || []);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},
	//

	async listAllAssets({ commit }, data) {
		try {
			const response = await assetService.getAsset(data);
			commit('SET_ALL_ASSETS', response.assets);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listAsset({ commit }, data) {
		try {
			commit('SET_ASSETS', []);
			const response = await assetService.getAsset(data);
			const pagination = {
				page: response?.assetsPagination.page,
				limit: response?.assetsPagination.limit,
				totalItems: response?.assetsPagination.totalItems,
				totalPages: response?.assetsPagination.totalPages,
			};
			commit('SET_ASSETS', response?.assets || []);
			commit('SET_ASSET_PAGINATION', pagination || {});
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async updateAsset({ commit }, { id, data }) {
		try {
			const response = await assetService.updateAsset(id, data);
			commit('UPDATE_ASSET', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async deleteAsset(__, id) {
		try {
			const response = await assetService.deleteAsset(id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getAssetByUser(__, data) {
		try {
			const response = await assetService.getAsset(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async downloadAssetFormat() {
		try {
			await assetService.downloadAssetFormat();
			return { ok: true };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async downloadExportAssets(__, data) {
		try {
			await assetService.getExportAsset(data);
			return { ok: true }
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async enableAsset(__, assetId) {
		try {
			const response = await assetService.enableAsset(assetId);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	cleanUser({ commit }) {
		commit('SET_USER', null);
	},

	cleanAsset({ commit }) {
		commit('SET_ASSET', null);
	},

	cleanAssets({ commit }) {
		commit('SET_ASSETS', []);
		//	commit('SET_ASSET', null);
		commit('SET_ASSET_PAGINATION', {
			page: 0,
			limit: 0,
			totalItems: 0,
			totalPages: 0,
		});
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
