import * as ratingTypeService from "./service/rating-type.service";

const state = {
  ratingType: null,
  ratingTypes: [],
  ratingTypesPagination: {
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
  },
  allRatingTypes: [],
};

const mutations = {
  SET_ALL_RATING_TYPE(state, data) {
    state.allRatingTypes = data;
  },

  SET_RATING_TYPES(state, payload) {
    state.ratingTypes = payload;
  },

  SET_RATING_TYPE_PAGINATION(state, payload) {
    state.ratingTypesPagination = { ...state.ratingTypesPagination, ...payload };
  },

  SET_PAGE(state, page) {
    state.ratingTypes.page = page;
  },

  SET_LIMIT(state, limit) {
    state.ratingTypes.limit = limit;
  },

//   UPDATE_SPARE_TYPE(state, updateSpareType) {
//     const index = state.spareTypes.findIndex(
//       (spareType) => spareType.id === updateSpareType.id
//     );
//     if (index !== -1) {
//       state.spareTypes.splice(index, 1, updateSpareType);
//     }
//   },

//   DELETE_SPARE_TYPE(state, spareTypeId) {
//     const index = state.spareTypes.findIndex((spareType) => spareType.id === spareTypeId);
//     if (index !== -1) {
//       state.spareTypes.splice(index, 1);
//     }
//   },
};

const actions = {
  async createRatingType( __, data) {
    try {
      const response = await ratingTypeService.saveRatingType(data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listAllRatingTypes({ commit }, data) {
    try {
      const response = await ratingTypeService.getRatingType(data);
      commit("SET_ALL_RATING_TYPE", response.ratings);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listRatingType({ commit }, data) {
    try {
      commit("SET_RATING_TYPES", []);
      const response = await ratingTypeService.getRatingType(data);
      const pagination = {
        page: response?.ratingsPagination.page,
        limit: response?.ratingsPagination.limit,
        totalItems: response?.ratingsPagination.totalItems,
        totalPages: response?.ratingsPagination.totalPages,
      };
      commit("SET_RATING_TYPES", response?.ratings || []);
      commit("SET_RATING_TYPE_PAGINATION", pagination || {});
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

//   async updateSpareType( commit, { id, data }) {
//     try {
//       const response = await spareTypeService.updateSpareType(id, data);
//       commit("UPDATE_SPARE_TYPE", response);
//       return { ok: true, response };
//     } catch (error) {
//       return { ok: false, error: error?.response || "Error en el servicio" };
//     }
//   },

//   async deleteSpareType(__, id) {
//     try {
//       const response = await spareTypeService.deleteSpareType(id);
//       return { ok: true, response };
//     } catch (error) {
//       return { ok: false, error: error?.response || "Error en el servicio" };
//     }
//   },
  /**/

  cleanUser({ commit }) {
    commit("SET_USER", null);
  },

  cleanSpareType({ commit }) {
    commit("SET_ALL_SPARE_TYPE", []);
  },

  // cleanAssets({ commit }) {
  //   commit("SET_ASSETS", []);
  //   commit("SET_ASSETS_PAGINATION", {
  //     page: 0,
  //     limit: 0,
  //     totalItems: 0,
  //     totalPages: 0,
  //   });
  // },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
