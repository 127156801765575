import * as ticketCommentService from './service/ticket-comment.service';

const state = {
    ticketComment: null,
    ticketComments: [],
    ticketCommentsPagination: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE,
        totalItems: 0,
        totalPages: 0,
    },
    allTicketComments: [],
    loadingTicketComment: false,
    filtersTicketComment: {
        ticket_number: null
    }
};

const mutations = {
    SET_FILTER_TICKET_COMMENT(state, data) {
        state.filtersTicketComment = data;
    },

    SET_LOADING_TICKET_COMMENT(state, data) {
        state.loadingTicketComment = data;
    },

    // SET_TICKET_COMMENT(state, ticketComment) {
    //     state.ticketComment = ticketComment;
    // },

    SET_ALL_TICKET_COMMENTS(state, data) {
        state.allTicketComments = data;
    },

    SET_TICKET_COMMENTS(state, payload) {
        state.ticketComments = payload;
    },

    SET_TICKET_COMMENT_PAGINATION(state, payload) {
        state.ticketCommentsPagination = { ...state.ticketCommentsPagination, ...payload };
    },

    SET_PAGE(state, page) {
        state.ticketComments.page = page;
    },

    SET_LIMIT(state, limit) {
        state.ticketComments.limit = limit;
    },

    // UPDATE_TICKET_COMMENT(state, updateTicketComment) {
    //     const index = state.ticketComments.findIndex((ticketComment) => ticketComment.id === updateTicketComment.id);
    //     if (index !== -1) {
    //         state.ticketComments.splice(index, 1, updateTicketComment);
    //     }
    // },

    // DELETE_TICKET_COMMENT(state, ticketCommentId) {
    //     const index = state.ticketComments.findIndex((ticketComment) => ticketComment.id === ticketCommentId);
    //     if (index !== -1) {
    //         state.ticketComments.splice(index, 1);
    //     }
    // },
};

const actions = {
    async createTicketComment(__, data) {
        try {
            const response = await ticketCommentService.saveTicketComment(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    // async getTicketCommentById({ commit }, id) {
    //     try {
    //         const response = await ticketCommentService.getTicketCommentById(id);
    //         commit('SET_TICKET_COMMENT', response);
    //         return { ok: true, response };
    //     } catch (error) {
    //         return { ok: false, error: error?.response || 'Error en el servicio' };
    //     }
    // },

    async listAllTicketComments({ commit }, data) {
        try {
            const response = await ticketCommentService.getTicketComment(data);
            commit('SET_ALL_TICKET_COMMENTS', response.ticketComments);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listTicketComments({ commit }, data) {
        try {
            commit('SET_TICKET_COMMENTS', []);
            const response = await ticketCommentService.getTicketComment(data);
            const pagination = {
                page: response?.ticketCommentsPagination.page,
                limit: response?.ticketCommentsPagination.limit,
                totalItems: response?.ticketCommentsPagination.totalItems,
                totalPages: response?.ticketCommentsPagination.totalPages,
            };
            commit('SET_TICKET_COMMENTS', response?.ticketComments || []);
            commit('SET_TICKET_COMMENT_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async getFileUrl(__, data) {
        try {
            const response = await ticketCommentService.getFileUrl(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    // async updateTicketComment({ commit }, { id, data }) {
    //     try {
    //         const response = await ticketCommentService.updateTicketComment(id, data);
    //         commit('UPDATE_TICKET_COMMENT', response);
    //         return { ok: true, response };
    //     } catch (error) {
    //         return { ok: false, error: error?.response || 'Error en el servicio' };
    //     }
    // },

    // async deleteTicketComment(__, id) {
    //     try {
    //         const response = await ticketCommentService.deleteTicketComment(id);
    //         return { ok: true, response };
    //     } catch (error) {
    //         return { ok: false, error: error?.response || 'Error en el servicio' };
    //     }
    // },

    // cleanUser({ commit }) {
    //     commit('SET_USER', null);
    // },

    cleanTicketComments({ commit }) {
        commit('SET_TICKET_COMMENTS', []);
        commit('SET_TICKET_COMMENT_PAGINATION', {
            page: 0,
            limit: 0,
            totalItems: 0,
            totalPages: 0,
        });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};