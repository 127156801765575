import { http } from "@/store/api/http-common";

const saveArea = async (params) => {
    try {
        const { data } = await http.post("areas", params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const saveAreaMultiple = async (params) => {
	try {
		const { data } = await http.post('areas/multiple-create', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const downloadAreaFormat = async () => {
	try {
		const response = await http.get('areas/format/download', {
			responseType: 'blob',
		});
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'FormatDeCargaMasivaArea.xlsx');
		document.body.appendChild(link);
		link.click();
	} catch (error) {
		return Promise.reject(error);
	}
};

const getArea = async (params) => {
    try {
        const { data } = await http.get("areas", { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const getAreaSubtypes = async (params) => {
    try {
        const { data } = await http.get("areas/subtypes", { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const updateArea = async (id, params) => {
    try{
        const { data } = await http.put(`areas/${id}`, params);
        return data || null;
    }catch(error){
        return Promise.reject(error);
    }
}

const deleteArea = async (id) => {
    try{
        const { data } = await http.delete(`areas/${id}`);
        return data || null;
    }catch(error){
        return Promise.reject(error);
    }
}
/** */

export { saveArea, getArea,updateArea,deleteArea,saveAreaMultiple,downloadAreaFormat,getAreaSubtypes };