import { http } from '../../../api/http-common';

const searchPersonal = async (params) => {
	try {
		const { data } = await http.get('programation', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const findOnePersonal = async (id) => {
	try {
		const { data } = await http.get(`programation/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	searchPersonal,
	findOnePersonal
};
