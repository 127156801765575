import { http } from "@/store/api/http-common";

const assignAsset = async (params) => {
  try {
    const { data } = await http.post("spare-history/assign-asset-spare", params);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSpareHistory = async (params) => {
  try {
    const { data } = await http.get("spare-history", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { assignAsset, getSpareHistory };
