import { http } from "@/store/api/http-common";

const saveSpareType = async (params) => {
  try {
    const { data } = await http.post("spare-types", params);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSpareType = async (params) => {
  try {
    const { data } = await http.get("spare-types", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateSpareType = async (id,params) => {
  try {
    const { data } = await http.put(`spare-types/${id}`, params);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteSpareType = async (id) => {
  try {
    const { data } = await http.delete(`spare-types/${id}`);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { saveSpareType, getSpareType, updateSpareType, deleteSpareType };
