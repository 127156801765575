<template>
	<v-app>
		<nav-bar @switchDrawer="activeDrawer" :moduleList="allowedModules" />
		<v-main class="b-grey">
			<router-view />
		</v-main>
		<v-navigation-drawer v-model="drawer" fixed temporary>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title v-if="user">{{ user.name }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>

			<v-list dense>
				<v-list-group
					v-for="item in allowedModules"
					:key="item.title"
					v-model="item.active"
					:prepend-icon="item.action"
					no-action
				>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</template>
					<v-list-item
						v-for="child in item.items"
						:key="child.title"
						:to="child.to"
						link
					>
						<v-list-item-content>
							<v-list-item-title>{{ child.title }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
			</v-list>
			<template v-slot:append>
				<div class="pa-2">
					<v-btn color="primary" class="text-none" block @click="onLogout()"
						>Cerrar Sesión</v-btn
					>
				</div>
			</template>
		</v-navigation-drawer>
		<snack-notification />
	</v-app>
</template>

<script>
import SnackNotification from '../components/global/SnackNotification.vue';
import NavBar from '../components/nav';
export default {
	name: 'DashboardLayout',

	data: () => ({
		drawer: null,
		items: [
			{
				title: 'Inventario',
				action: 'mdi-clipboard-list-outline',
				to: { name: 'InventoryHomePage' },
				moduleName: 'inventario',
				showInNav: true,
				childRouteNames: [
					'InventoryHomePage',
					'AssetsPermissions',
					'SparesPermissions',
					'DetailActiveInventory',
					'DetailSpareInventory',
					// 'CreateContract',
					// 'ContractReport',
					// 'ContractNotification',
					// 'documentShow',
				],
				items: [
					{
						title: 'Activos',
						action: 'mdi-monitor-cellphone',
						moduleName: 'inventario',
						pageName: 'listaActivos',
						to: { name: 'AssetsPermissions' },
					},
					{
						title: 'Repuestos',
						action: 'mdi-hammer-wrench',
						moduleName: 'inventario',
						pageName: 'listaRepuestos',
						to: { name: 'SparesPermissions' },
					},
				],
			},
			{
				title:'Permisos de Aplicación',
				action: 'mdi-account-supervisor',
				to: { name: 'PermissionHomePage' },
				moduleName: 'permisosAplicacion',
				showInNav: true,
				childRouteNames: [
					'PermissionHomePage',
					'AreasPermissions',
					'ClassificationsPermissions',
					'SubtypesPermissions',
					'SubtypesServicePermissions'
					// 'DetailSpareInventory',
					// 'CreateContract',
					// 'ContractReport',
					// 'ContractNotification',
					// 'documentShow',
				],
				items: [
					{
						title: 'Áreas',
						action: 'mdi-account-group',
						moduleName: 'permisosAplicacion',
						pageName: 'listaArea',
						to: { name: 'AreasPermissions' },
					},
					{
						title: 'Clasificaciones',
						action: 'mdi-format-list-bulleted-type',
						moduleName: 'permisosAplicacion',
						pageName: 'listaClasificacion',
						to: { name: 'ClassificationsPermissions' },
					},
					{
						title: 'Subtipos',
						action: 'mdi-sitemap-outline',
						moduleName: 'permisosAplicacion',
						pageName: 'listaSubTipos',
						to: { name: 'SubtypesPermissions' },
					},
					{
						title: 'Subtipos de servicio',
						action: 'mdi-file-tree',
						moduleName: 'permisosAplicacion',
						pageName: 'listaSubTipos',
						to: { name: 'SubtypesServicePermissions' },
					},
				],
			}
		],
	}),
	computed: {
		allowedModules() {
			return this.getAllowedModules();
		},
		user() {
			return this.$store.state.auth.user || null;
		},
	},
	methods: {
		activeDrawer() {
			this.drawer = true;
		},
		getInitialName() {
			if (this.user?.name && this.user?.last_name_father) {
				const name = this.user?.name;
				const lastName = this.user?.last_name_father;
				return `${name[0]}${lastName[0]}`;
			}
		},
		onLogout() {
			this.$store.dispatch('auth/logout');
			this.$router.push({ name: 'Login' });
		},
		getAllowedModules() {
			const activities = this.$store.state.auth.activities || [];

			let modulesList = this.items || [];
		
			modulesList = modulesList.filter((item) => {

				let found = false;
				if (Array.isArray(activities)) {
					found = activities.some((activity) => {
						if (item.moduleName && item.pageName) {
							return (
								activity.module_name == item.moduleName &&
								activity.page_name == item.pageName
							);
						} else {
							return true;
						}
					});
				}

				return found;
			});
			return modulesList;
		},
	},
	components: {
		NavBar,
		SnackNotification,
	},
};
</script>
