import * as ticketRatingService from './service/ticket-rating.service';

const state = {
    ticketRating: null,
    ticketRatings: [],
    ticketRatingsPagination: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE,
        totalItems: 0,
        totalPages: 0,
    },
    allTicketRatings: [],
    loadingTicketRating: false,
    filtersTicketRating: {
        ticket_number: null,
        status: null,
        area: null
    }
};

const mutations = {
    SET_FILTER_TICKET_RATING(state, data) {
        state.filtersTicketRating = data;
    },

    SET_LOADING_TICKET_RATING(state, data) {
        state.loadingTicketRating = data;
    },

    SET_TICKET_RATING(state, ticketRating) {
        state.ticketRating = ticketRating;
    },

    SET_ALL_TICKETS_RATING(state, data) {
        state.allTicketRatings = data;
    },

    SET_TICKETS_RATING(state, payload) {
        state.ticketRatings = payload;
    },

    SET_TICKET_RATING_PAGINATION(state, payload) {
        state.ticketRatingsPagination = { ...state.ticketRatingsPagination, ...payload };
    },

    SET_PAGE(state, page) {
        state.ticketRatings.page = page;
    },

    SET_LIMIT(state, limit) {
        state.ticketRatings.limit = limit;
    },

    // UPDATE_TICKET(state, updateTicket) {
    //     const index = state.tickets.findIndex((ticket) => ticket.id === updateTicket.id);
    //     if (index !== -1) {
    //         state.tickets.splice(index, 1, updateTicket);
    //     }
    // },

    DELETE_TICKET_RATING(state, ticketRatingId) {
        const index = state.ticketRatings.findIndex((ticketRating) => ticketRating.id === ticketRatingId);
        if (index !== -1) {
            state.ticketRatings.splice(index, 1);
        }
    },
};

const actions = {
    async createTicketRating(__, data) {
        try {
            const response = await ticketRatingService.saveTicketRating(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async getTicketRatingById({ commit }, id) {
        try {
            const response = await ticketRatingService.getTicketRatingById(id);
            commit('SET_TICKET_RATING', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listAllTicketsRating({ commit }, data) {
        try {
            const response = await ticketRatingService.getTicketRating(data);
            commit('SET_ALL_TICKETS_RATING', response.ticketRating);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listTicketRating({ commit }, data) {
        try {
            commit('SET_TICKETS_RATING', []);
            const response = await ticketRatingService.getTicketRating(data);
            const pagination = {
                page: response?.ticketRatingPagination.page,
                limit: response?.ticketRatingPagination.limit,
                totalItems: response?.ticketRatingPagination.totalItems,
                totalPages: response?.ticketRatingPagination.totalPages,
            };
            commit('SET_TICKETS_RATING', response?.tickets || []);
            commit('SET_TICKET_RATING_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    // async updateTicket({ commit }, { id, data }) {
    //     try {
    //         const response = await ticketRatingService.updateTicket(id, data);
    //         commit('UPDATE_TICKET', response);
    //         return { ok: true, response };
    //     } catch (error) {
    //         return { ok: false, error: error?.response || 'Error en el servicio' };
    //     }
    // },

    // async deleteTicket(__, id) {
    //     try {
    //         const response = await ticketRatingService.deleteTicket(id);
    //         return { ok: true, response };
    //     } catch (error) {
    //         return { ok: false, error: error?.response || 'Error en el servicio' };
    //     }
    // },

    // cleanUser({ commit }) {
    //     commit('SET_USER', null);
    // },

    cleanTickets({ commit }) {
        commit('SET_TICKETS_RATING', []);
        commit('SET_TICKET_RATING_PAGINATION', {
            page: 0,
            limit: 0,
            totalItems: 0,
            totalPages: 0,
        });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};