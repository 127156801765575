import { http } from "@/store/api/http-common";

const saveSubtype = async (params) => {
    try {
        const { data } = await http.post("subtypes", params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const getSubtype = async (params) => {
    try {
        const { data } = await http.get("subtypes", { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

/*x confirmar */
const updateSubtype = async (id, params) => {
    try{
        const { data } = await http.put(`subtypes/${id}`, params);
        return data || null;
    }catch(error){
        return Promise.reject(error);
    }
}

const deleteSubtype = async (id) => {
    try{
        const { data } = await http.delete(`subtypes/${id}`);
        return data || null;
    }catch(error){
        return Promise.reject(error);
    }
}
/** */

export { saveSubtype, getSubtype,updateSubtype,deleteSubtype };