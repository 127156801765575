import * as spareAssignService from './service/spare-assign.service';

const state = {
    spareAssign: null,
    sparesAssign: [],
    spareAssignPagination: {
        page: 1,
        limit: 10,
        totalItems: 0,
        totalPages: 0,
    },
    allSparesAssign: [],
    loadingSpareAssign: false,
    // filtersSpareAssign: {
    // 	spareUnsubscribe_type: null,
    // },
};

const mutations = {
    SET_SPARE_ASSIGN(state, spareAssign) {
        state.spareAssign = spareAssign;
    },

    SET_ALL_SPARES_ASSIGN(state, data) {
        state.allSparesAssign = data;
    },

    SET_SPARES_ASSIGN(state, payload) {
        state.sparesAssign = payload;
    },

    SET_SPARE_ASSIGN_PAGINATION(state, payload) {
        state.spareAssignPagination = {
            ...state.spareAssignPagination,
            ...payload,
        };
    },
}

const actions = {
    async createSpareAssign(__, { data }) {
        try {
            const response = await spareAssignService.saveSpareAssign(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    async getSpareAssingById({ commit }, id) {
        try {
            const response = await spareAssignService.getSpareAssignById(id);
            commit('SET_SPARE_ASSIGN', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listAllSpareAssign({ commit }, data) {
        try {
            const response = await spareAssignService.getSpareAssign(data);
            commit('SET_ALL_SPARES_ASSIGN', response.spareAssign);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listSpareAssign({ commit }, data) {
        try {
            commit('SET_SPARES_ASSIGN', []);
            const response = await spareAssignService.getSpareAssign(data);
            const pagination = {
                page: response?.spareAssignPagination.page,
                limit: response?.spareAssignPagination.limit,
                totalItems: response?.spareAssignPagination.totalItems,
                totalPages: response?.spareAssignPagination.totalPages,
            };
            commit('SET_SPARES_ASSIGN', response?.spareUnsubscribes || []);
            commit('SET_SPARE_ASSIGN_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    cleanSpareAssign({commit}){
		commit('SET_SPARE_ASSIGN', null);
	},
}


const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
