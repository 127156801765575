import { http } from '@/store/api/http-common';

const saveTicketRating = async (params) => {
    try {
        const { data } = await http.post('ticket-ratings', params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getTicketRatingById = async (id) => {
    try {
        const { data } = await http.get(`ticket-ratings/${id}`);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getTicketRating = async (params) => {
    try {
        const { data } = await http.get('ticket-ratings', { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const deleteTicketRating = async (params) => {
    try {
        const { data } = await http.get('ticket-ratings', { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

export {
    saveTicketRating,
    getTicketRatingById,
    getTicketRating,
    deleteTicketRating
};