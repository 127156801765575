import * as assetAssignService from './service/asset-assign.service';

const state = {
    assetAssign: null,
    assetsAssign: [],
    assetAssignPagination: {
        page: 1,
        limit: 10,
        totalItems: 0,
        totalPages: 0,
    },
    allAssetsAssign: [],
    loadingAssetAssign: false,
    assetAssignCertificate: null,
    // filtersSpareAssign: {
    // 	spareUnsubscribe_type: null,
    // },
};

const mutations = {
    SET_ASSET_ASSIGN(state, assetAssign) {
        state.assetAssign = assetAssign;
    },

    SET_ASSET_ASSIGN_CERTIFICATE(state, assetAssignCertificate) {
        state.assetAssignCertificate = assetAssignCertificate;
    },

    SET_ALL_ASSETS_ASSIGN(state, data) {
        state.allAssetsAssign = data;
    },

    SET_ASSETS_ASSIGN(state, payload) {
        state.assetsAssign = payload;
    },

    SET_ASSET_ASSIGN_PAGINATION(state, payload) {
        state.assetAssignPagination = {
            ...state.assetAssignPagination,
            ...payload,
        };
    },

    UPDATE_ASSET_ASSIGN(state, updateAssetAssign) {
        const index = state.assetsAssign.findIndex((assetAssign) => assetAssign.id === updateAssetAssign.id);
        if (index !== -1) {
            state.assetsAssign.splice(index, 1, updateAssetAssign);
        }
    },
}

const actions = {
    async createAssetAssign(__, { data }) {
        try {
            const response = await assetAssignService.saveAssetAssign(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    async getAssetAssignmentById({ commit }, id) {
        try {
            const response = await assetAssignService.getAssetAssignmentById(id);
            commit('SET_ASSET_ASSIGN', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    // async getAssetAssignCertificateById({ commit }, id) {
    //     try {
    //         const response = await assetAssignService.getAssetAssignmentCertificateById(id);
    //         commit('SET_ASSET_ASSIGN_CERTIFICATE', response);
    //         return { ok: true, response };
    //     } catch (error) {
    //         return { ok: false, error: error?.response || 'Error en el servicio' };
    //     }
    // },

    async getAssetAssignCertificateById(_, { id, data }) {
        try {
            const response = await assetAssignService.getAssetAssignmentCertificateById(id, data);
            // commit('SET_ASSET_ASSIGN_CERTIFICATE', response); // Esta línea parece no ser necesaria
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async getAssetReturnCertificate(_, { id, data }) {
        try {
            const response = await assetAssignService.getCertificateReturnAssetById(id, data);
            // commit('SET_ASSET_ASSIGN_CERTIFICATE', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    // async getSpareAssingById({ commit }, id) {
    //     try {
    //         const response = await assetAssignService.getSpareAssignById(id);
    //         commit('SET_SPARE_ASSIGN', response);
    //         return { ok: true, response };
    //     } catch (error) {
    //         return { ok: false, error: error?.response || 'Error en el servicio' };
    //     }
    // },

    async listAllAssetAssign({ commit }, data) {
        try {
            const response = await assetAssignService.getAssetAssignments(data);
            commit('SET_ALL_ASSETS_ASSIGN', response.assetAssign);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listSpareAssign({ commit }, data) {
        try {
            commit('SET_ASSETS_ASSIGN', []);
            const response = await assetAssignService.getAssetAssignments(data);
            const pagination = {
                page: response?.assetAssignPagination.page,
                limit: response?.assetAssignPagination.limit,
                totalItems: response?.assetAssignPagination.totalItems,
                totalPages: response?.assetAssignPagination.totalPages,
            };
            commit('SET_ASSETS_ASSIGN', response?.spareUnsubscribes || []);
            commit('SET_ASSET_ASSIGN_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async cancelPreassignmentAsset({ commit }, { id, data }) {
        try {
            const response = await assetAssignService.cancelPreassignment(id, data);
            commit('UPDATE_ASSET_ASSIGN', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async declinePreassignmentAsset({ commit }, { id, data }) {
        try {
            const response = await assetAssignService.declinePreassignment(id, data);
            commit('UPDATE_ASSET_ASSIGN', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async confirmPreassignmentAsset({ commit }, { id, data }) {
        try {
            const response = await assetAssignService.confirmPreassignment(id, data);
            commit('UPDATE_ASSET_ASSIGN', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    //async confirmReturnAsset({ commit }, { id, data }) {
    async confirmReturnAsset(__, { id, data }) {
        try {
            const response = await assetAssignService.confirmReturn(id, data);
            //commit('UPDATE_ASSET_ASSIGN', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async assetReturn(__, { id, data }) {
        try {
            const response = await assetAssignService.returnAsset(id, data);
            //commit('UPDATE_ASSET_ASSIGN', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async notificationManual(__, { id, data }) {
        try {
            const response = await assetAssignService.notificationManual(id, data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async addSignerUserAssetAssign(__, { data }) {
        try {
         
            const response = await assetAssignService.saveUserSigner(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    cleanAssetAssign({ commit }) {
        commit('SET_ASSET_ASSIGN', null);
    },

    cleanAssetsAssign({ commit }) {
        commit('SET_ASSETS_ASSIGN', []);
        commit('SET_ASSET_ASSIGN_PAGINATION', {
            page: 0,
            limit: 0,
            totalItems: 0,
            totalPages: 0,
        });
    },
}


const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
