import store from '@/store';

const isAuthenticatedGuard = async (to, from, next) => {
	const { ok } = await store.dispatch('auth/checkAuthentication');
	const { activities, flag_password_reset } = await store.state.auth;
	const metas = to.matched[1].meta;

	if (ok) {
		if (flag_password_reset) {
			if (to.name !== 'UserChangePassword') {
				next({ name: 'UserChangePassword' });
			} else {
				next();
			}
		} else {
			if (metas.moduleName && metas.pageName) {
				const foundActivity = activities.find((activity) => {
					return (
						activity.module_name == metas.moduleName &&
						activity.page_name == metas.pageName
					);
				});
				if (foundActivity) {
					next();
				} else {
					next({ name: 'NotAuthorized' });
				}
			} else {
				next();
			}
		}
	} else {
		next({ name: 'Login' });
	}
};

export default isAuthenticatedGuard;
