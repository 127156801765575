import { http } from "@/store/api/http-common";

const saveClassification = async (params) => {
    try {
        const { data } = await http.post("classifications", params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const saveClasificationMultiple = async (params) => {
    try {
        const { data } = await http.post('classifications/multiple-create', params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const downloadClasificationFormat = async () => {
    try {
        const response = await http.get('classifications/format/download', {
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'FormatDeCargaMasivaClasificacion.xlsx');
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        return Promise.reject(error);
    }
};

const getClassification = async (params) => {
    try {
        const { data } = await http.get("classifications", { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const getClassificationById = async (id) => {
    try {
        const { data } = await http.get(`classifications/${id}`);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const updateClassification = async (id, params) => {
    try {
        const { data } = await http.put(`classifications/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const deleteClassification = async (id) => {
    try {
        const { data } = await http.delete(`classifications/${id}`);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}
/** */

export { saveClassification, getClassification, updateClassification, deleteClassification, saveClasificationMultiple, downloadClasificationFormat, getClassificationById };