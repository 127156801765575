import { http } from '@/store/api/http-common';

const saveTicketComment = async (params) => {
    try {
        const { data } = await http.post('ticket-comments', params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

// const getTicketCommentById = async (id) => {
//     try {
//         const { data } = await http.get(`ticket-comments/${id}`);
//         return data || null;
//     } catch (error) {
//         return Promise.reject(error);
//     }
// };

const getTicketComment = async (params) => {
    try {
        const { data } = await http.get('ticket-comments', { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getFileUrl = async (params) => {
	try {
		const { data } = await http.get(`ticket-comments/get-file-url`, { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
    saveTicketComment,
    getTicketComment,
    getFileUrl,
    // getTicketCommentById,
};