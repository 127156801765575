import * as spareUnsubscribeService from './service/spare-unsubscribe.service';

const state = {
	spareUnsubscribe: null,
	sparesUnsubscribe: [],
	spareUnsubscribePagination: {
		page: 1,
		limit: 10,
		totalItems: 0,
		totalPages: 0,
	},
	allSparesUnsubscribe: [],
	loadingSpareUnsubscribe: false,
	filtersSpareUnsubscribe: {
		spareUnsubscribe_type: null,
	},
};

const mutations = {
	SET_FILTER_SPARE_UNSUBSCRIBE(state, data) {
		state.filtersSpareUnsubscribe = data;
	},

	SET_LOADING_SPARE_UNSUBSCRIBE(state, data) {
		state.loadingSpareUnsubscribe = data;
	},

	//
	SET_SPARE_UNSUBSCRIBE(state, asset) {
		state.spareUnsubscribe = asset;
	},
	//

	SET_ALL_SPARES_UNSUBSCRIBE(state, data) {
		state.allSparesUnsubscribe = data;
	},

	SET_SPARES_UNSUBSCRIBE(state, payload) {
		state.sparesUnsubscribe = payload;
	},

	SET_SPARE_UNSUBSCRIBE_PAGINATION(state, payload) {
		state.spareUnsubscribePagination = {
			...state.spareUnsubscribePagination,
			...payload,
		};
	},

	SET_PAGE_SPARE_UNSUBSCRIBE(state, page) {
		state.sparesUnsubscribe.page = page;
	},

	SET_LIMIT_SPARE_UNSUBSCRIBE(state, limit) {
		state.sparesUnsubscribe.limit = limit;
	},

	UPDATE_SPARE_UNSUBSCRIBE(state, updateSpareUnsubscribe) {
		const index = state.sparesUnsubscribe.findIndex(
			(spareUnsubscribe) => spareUnsubscribe.id === updateSpareUnsubscribe.id
		);
		if (index !== -1) {
			state.sparesUnsubscribe.splice(index, 1, updateSpareUnsubscribe);
		}
	},

	DELETE_ASSET_UNSUBSCRIBE(state, spareUnsubscribeId) {
		const index = state.sparesUnsubscribe.findIndex(
			(spareUnsubscribe) => spareUnsubscribe.id === spareUnsubscribeId
		);
		if (index !== -1) {
			state.sparesUnsubscribe.splice(index, 1);
		}
	},
};

const actions = {
	async getSpareUnsubscribeById({ commit }, id) {
		try {
			const response = await spareUnsubscribeService.getSpareUnsubscribeById(id);
			commit('SET_SPARE_UNSUBSCRIBE', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listAllSparesUnsubscribe({ commit }, data) {
		try {
			const response = await spareUnsubscribeService.getSpareUnsubscribe(data);
			commit('SET_ALL_SPARES_UNSUBSCRIBE', response.spareUnsubscribes);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listSpareUnsubscribe({ commit }, data) {
		try {
			commit('SET_SPARES_UNSUBSCRIBE', []);
			const response = await spareUnsubscribeService.getSpareUnsubscribe(data);
			const pagination = {
				page: response?.spareUnsubscribesPagination.page,
				limit: response?.spareUnsubscribesPagination.limit,
				totalItems: response?.spareUnsubscribesPagination.totalItems,
				totalPages: response?.spareUnsubscribesPagination.totalPages,
			};
			commit('SET_SPARES_UNSUBSCRIBE', response?.spareUnsubscribes || []);
			commit('SET_SPARE_UNSUBSCRIBE_PAGINATION', pagination || {});
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
