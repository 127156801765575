import * as assetUnsubscribeService from "./service/asset-unsubscribe.service";

const state = {
  assetUnsubscribe: null,
  assetsUnsubscribe: [],
  assetUnsubscribePagination: {
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
  },
  allAssetsUnsubscribe: [],
  loadingAssetUnsubscribe: false,
  filtersAssetUnsubscribe: {
    assetUnsubscribe_type: null,
  },
};

const mutations = {
    SET_FILTER_ASSET_UNSUBSCRIBE(state, data) {
        state.filtersAssetUnsubscribe = data;
      },
    
      SET_LOADING_ASSET_UNSUBSCRIBE(state, data) {
        state.loadingAssetUnsubscribe = data;
      },
    
      //
      SET_ASSET_UNSUBSCRIBE(state, asset) {
        state.assetUnsubscribe = asset;
      },
      //
    
      SET_ALL_ASSETS_UNSUBSCRIBE(state, data) {
        state.allAssetsUnsubscribe = data;
      },
    
      SET_ASSETS_UNSUBSCRIBE(state, payload) {
        state.assetsUnsubscribe = payload;
      },
    
      SET_ASSET_UNSUBSCRIBE_PAGINATION(state, payload) {
        state.assetUnsubscribePagination = {
          ...state.assetUnsubscribePagination,
          ...payload,
        };
      },
    
      SET_PAGE_ASSET_UNSUBSCRIBE(state, page) {
        state.assetsUnsubscribe.page = page;
      },
    
      SET_LIMIT_ASSET_UNSUBSCRIBE(state, limit) {
        state.assetsUnsubscribe.limit = limit;
      },
    
      UPDATE_ASSET_UNSUBSCRIBE(state, updateAssetUnsubscribe) {
        const index = state.assetsUnsubscribe.findIndex(
          (assetUnsubscribe) => assetUnsubscribe.id === updateAssetUnsubscribe.id
        );
        if (index !== -1) {
          state.assetsUnsubscribe.splice(index, 1, updateAssetUnsubscribe);
        }
      },
    
      DELETE_ASSET_UNSUBSCRIBE(state, assetUnsubscribeId) {
        const index = state.assetsUnsubscribe.findIndex(
          (assetUnsubscribe) => assetUnsubscribe.id === assetUnsubscribeId
        );
        if (index !== -1) {
          state.assetsUnsubscribe.splice(index, 1);
        }
      },
};

const actions = {
    async getAssetUnsubscribeById({ commit }, id) {
        try {
          const response = await assetUnsubscribeService.getAssetUnsubscribeById(id);
          commit("SET_ASSET_UNSUBSCRIBE", response);
          return { ok: true, response };
        } catch (error) {
          return { ok: false, error: error?.response || "Error en el servicio" };
        }
      },

      async listAllAssetsUnsubscribe({ commit }, data) {
        try {
          const response = await assetUnsubscribeService.getAssetUnsubscribe(data);
          commit("SET_ALL_ASSETS_UNSUBSCRIBE", response.assetUnsubscribes);
          return { ok: true, response };
        } catch (error) {
          return { ok: false, error: error?.response || "Error en el servicio" };
        }
      },
    
      async listAssetUnsubscribe({ commit }, data) {
        try {
          commit("SET_ASSETS_UNSUBSCRIBE", []);
          const response = await assetUnsubscribeService.getAssetUnsubscribe(data);
          const pagination = {
            page: response?.assetUnsubscribesPagination.page,
            limit: response?.assetUnsubscribesPagination.limit,
            totalItems: response?.assetUnsubscribesPagination.totalItems,
            totalPages: response?.assetUnsubscribesPagination.totalPages,
          };
          commit("SET_ASSETS_UNSUBSCRIBE", response?.assetUnsubscribes || []);
          commit("SET_ASSET_UNSUBSCRIBE_PAGINATION", pagination || {});
          return { ok: true, response };
        } catch (error) {
          return { ok: false, error: error?.response || "Error en el servicio" };
        }
      },

      async getAssetReturUnsubscribeCertificate(_, id) {
        try {
            const response = await assetUnsubscribeService.getCertificateAssetUnsubscribeById(id);
            // commit('SET_ASSET_ASSIGN_CERTIFICATE', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
