import { http } from "@/store/api/http-common";

const saveServiceSubtype = async (params) => {
    try {
        const { data } = await http.post("service-subtypes", params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const getAllServiceSubtypes = async (params) => {
    try {
        const { data } = await http.get("service-subtypes", { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const getServiceSubtypeById = async (id) => {
	try {
		const { data } = await http.get(`service-subtypes/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

/*x confirmar */
const updateServiceSubtype = async ( params) => {
    try{
        const { data } = await http.put(`service-subtypes`, params);
        return data || null;
    }catch(error){
        return Promise.reject(error);
    }
}

export { saveServiceSubtype, getAllServiceSubtypes,getServiceSubtypeById,updateServiceSubtype };