import * as personalService from './service/personal.service';

const state = {
	user: null,
	users: [],
	users_pagination: null,
};

const mutations = {
	SET_USER(state, payload) {
		state.user = payload;
	},

	SET_USERS(state, payload) {
		state.users = payload;
	},

	SET_USERS_PAGINATION(state, payload) {
		state.users_pagination = payload;
	},
};

const actions = {

	async searchPersonal({ commit }, data) {
		try {
			const response = await personalService.searchPersonal(data);
			commit('SET_USERS', response?.users || []);
			commit('SET_USERS_PAGINATION', response?.pagination || null);
			return { ok: true, response: response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async findOnePersonal({ commit }, id) {
		try {
			const response = await personalService.findOnePersonal(id);
			commit('SET_USER', response?.data || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	cleanUsers({ commit }) {
		commit('SET_USERS', []);
		commit('SET_USERS_PAGINATION', null);
	},
	
	cleanUser({ commit }) {
		commit('SET_USER', null);
	},
};

const getters = {
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
