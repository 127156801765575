import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/axios';
import "./plugins/sweetalert";
import './plugins/vuedatepicker';
import './plugins/vueApexCharts.js';
import './assets/scss/main.scss';

export const $EventBus = new Vue();

Vue.mixin({
	data: function () {
		return {
			globalVar: 'Titulo',
		};
	},
});
Vue.component(
	'ValidateActivity',
	require('./components/global/ValidateActivity.vue').default
);
Vue.prototype.$appTitle = 'My App'
Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
