import { http } from '@/store/api/http-common';

const getPendingRatingByTicket = async (params) => {
	try {
		const { data } = await http.get(
			'ticket-ratings/get-pending-ratings/' + params?.ticketId,
			{ params }
		);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getRatings = async (params) => {
	try {
		const { data } = await http.get('ticket-ratings', {
			params,
		});
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const createRatings = async (payload) => {
	try {
		const { data } = await http.post('ticket-ratings', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export { getPendingRatingByTicket, getRatings, createRatings };
