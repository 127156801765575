import * as assetTransferService from './service/asset-transfer.service';

const state = {
    assetTransfer: null,
    assetsTransfer: [],
    assetTransferPagination: {
        page: 1,
        limit: 10,
        totalItems: 0,
        totalPages: 0,
    },
    allAssetsTransfer: [],
    loadingAssetTransfer: false,
    // filtersSpareAssign: {
    // 	spareUnsubscribe_type: null,
    // },
};

const mutations = {
    SET_ASSET_TRANSFER(state, assetTransfer) {
        state.assetTransfer = assetTransfer;
    },

    SET_ALL_ASSETS_TRANSFER(state, data) {
        state.allAssetsTransfer = data;
    },

    SET_ASSETS_TRANSFER(state, payload) {
        state.assetsTransfer = payload;
    },

    SET_ASSET_TRANSFER_PAGINATION(state, payload) {
        state.assetTransferPagination = {
            ...state.assetTransferPagination,
            ...payload,
        };
    },
}

const actions = {
    async createAssetTransfer(__, { data }) {
        try {
            const response = await assetTransferService.saveAssetTransfer(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    async getSpareAssingById({ commit }, id) {
        try {
            const response = await assetTransferService.getAssetTransferById(id);
            commit('SET_ASSET_TRANSFER', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listAllSpareAssign({ commit }, data) {
        try {
            const response = await assetTransferService.getAssetTransfer(data);
            commit('SET_ALL_ASSETS_TRANSFER', response.assetTransfer);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listSpareAssign({ commit }, data) {
        try {
            commit('SET_ASSETS_TRANSFER', []);
            const response = await assetTransferService.getAssetTransfer(data);
            const pagination = {
                page: response?.assetTransferPagination.page,
                limit: response?.assetTransferPagination.limit,
                totalItems: response?.assetTransferPagination.totalItems,
                totalPages: response?.assetTransferPagination.totalPages,
            };
            commit('SET_ASSETS_TRANSFER', response?.assetTransfer || []);
            commit('SET_ASSET_TRANSFER_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    cleanAssetsTransfer({ commit }) {
        commit('SET_ASSETS_TRANSFER', []);
        commit('SET_ASSET_TRANSFER', null);
        commit('SET_ASSET_TRANSFER_PAGINATION', {
            page: 0,
            limit: 0,
            totalItems: 0,
            totalPages: 0,
        });
    },
}




const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
