import { http } from '@/store/api/http-common';

const createIncident = async (params) => {
	try {
		const { data } = await http.post('tickets', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};
export { createIncident };
