import * as spareService from './service/spare.service';

const state = {
	spare: null,
	spares: [],
	sparesPagination: {
		page: 1,
		limit: 10,
		totalItems: 0,
		totalPages: 0,
	},
	allSpares: [],
	loadingSpare: false,
	filtersSpare: {
		spare_type: null,
		status: null,
	},
};

const mutations = {
	SET_FILTER_SPARE(state, data) {
		state.filtersSpare = data;
	},

	SET_LOADING_SPARE(state, data) {
		state.loadingSpare = data;
	},

	//
	SET_SPARE(state, spare) {
		state.spare = spare;
	},
	//

	SET_ALL_SPARES(state, data) {
		state.allSpares = data;
	},

	SET_SPARES(state, payload) {
		state.spares = payload;
	},

	SET_SPARE_PAGINATION(state, payload) {
		state.sparesPagination = { ...state.sparesPagination, ...payload };
	},

	SET_PAGE(state, page) {
		state.spares.page = page;
	},

	SET_LIMIT(state, limit) {
		state.spares.limit = limit;
	},

	UPDATE_SPARE(state, updateSpare) {
		const index = state.spares.findIndex((spare) => spare.id === updateSpare.id);
		if (index !== -1) {
			state.spares.splice(index, 1, updateSpare);
		}
	},

	DELETE_SPARE(state, spareId) {
		const index = state.spares.findIndex((spare) => spare.id === spareId);
		if (index !== -1) {
			state.spares.splice(index, 1);
		}
	},
};

const actions = {
	async createSpare(__, data) {
		try {
			const response = await spareService.saveSpare(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	
	async createSpareMultiple(__, data) {
		try {
			const response = await spareService.saveSpareMultiple(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getSpareById({ commit }, id) {
		try {
			const response = await spareService.getSpareById(id);
			commit('SET_SPARE', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async cancelSpare({ commit }, { id, data }) {
		try {
			const response = await spareService.cancelSpare(id, data);
			commit('UPDATE_SPARE', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async reestablishSpare({ commit },  id ) {
		try {
			const response = await spareService.reestablishSpare(id);
			commit('UPDATE_SPARE', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},
	

	async listAllSpares({ commit }, data) {
		try {
			const response = await spareService.getSpare(data);
			commit('SET_ALL_SPARES', response.spares);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listSpare({ commit }, data) {
		try {
			commit('SET_SPARES', []);
			const response = await spareService.getSpare(data);
			const pagination = {
				page: response?.sparesPagination.page,
				limit: response?.sparesPagination.limit,
				totalItems: response?.sparesPagination.totalItems,
				totalPages: response?.sparesPagination.totalPages,
			};
			commit('SET_SPARES', response?.spares || []);
			commit('SET_SPARE_PAGINATION', pagination || {});
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async updateSpare({ commit }, { id, data }) {
		try {
			const response = await spareService.updateSpare(id, data);
			commit('UPDATE_SPARE', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async deleteSpare(__, id) {
		try {
			const response = await spareService.deleteSpare(id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async downloadSpareFormat() {
		try {
			await spareService.downloadSpareFormat();
			return { ok: true };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async downloadExportSpares(__, data) {
		try {
			await spareService.getExportSpare(data);
			return { ok: true }
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},
	/**/

	cleanUser({ commit }) {
		commit('SET_USER', null);
	},

	cleanSpare({commit}){
		commit('SET_SPARE', null);
	},

	cleanSpares({ commit }) {
		commit('SET_SPARES', []);
		commit('SET_SPARE_PAGINATION', {
			page: 0,
			limit: 0,
			totalItems: 0,
			totalPages: 0,
		});
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
