import * as assetTypeService from "./service/asset-type.service";

const state = {
  assetType: null,
  assetTypes: [],
  assetTypesPagination: {
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
  },
  allAssetTypes: [],
};

const mutations = {
  SET_ALL_ASSET_TYPE(state, data) {
    state.allAssetTypes = data;
  },

  SET_ASSET_TYPES(state, payload) {
    state.assetTypes = payload;
  },

  SET_ASSET_TYPE_PAGINATION(state, payload) {
    state.assetTypesPagination = { ...state.assetTypesPagination, ...payload };
  },

  SET_PAGE(state, page) {
    state.assetTypes.page = page;
  },

  SET_LIMIT(state, limit) {
    state.assetTypes.limit = limit;
  },

  UPDATE_ASSET_TYPE(state, updateAssetType) {
    const index = state.assetTypes.findIndex(
      (assetType) => assetType.id === updateAssetType.id
    );
    if (index !== -1) {
      state.assetTypes.splice(index, 1, updateAssetType);
    }
  },

  DELETE_ASSET_TYPE(state, assetTypeId) {
    const index = state.assetTypes.findIndex((assetType) => assetType.id === assetTypeId);
    if (index !== -1) {
      state.assetTypes.splice(index, 1);
    }
  },
};

const actions = {
  async createAssetType(__, data) {
    try {
      const response = await assetTypeService.saveAssetType(data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listAllAssetTypes({ commit }, data) {
    try {
      const response = await assetTypeService.getAssetType(data);
      commit("SET_ALL_ASSET_TYPE", response.assetTypes);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listAssetType({ commit }, data) {
    try {
      commit("SET_ASSET_TYPES", []);
      const response = await assetTypeService.getAssetType(data);
      const pagination = {
        page: response?.assetTypesPagination.page,
        limit: response?.assetTypesPagination.limit,
        totalItems: response?.assetTypesPagination.totalItems,
        totalPages: response?.assetTypesPagination.totalPages,
      };
      commit("SET_ASSET_TYPES", response?.assetTypes || []);
      commit("SET_ASSET_TYPE_PAGINATION", pagination || {});
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async updateAssetSubType({ commit }, { id, data }) {
    try {
      const response = await assetTypeService.updateAssetType(id, data);
      commit("UPDATE_ASSET_TYPE", response);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async deleteAssetType(__, id) {
    try {
      const response = await assetTypeService.deleteAssetType(id);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },
  /**/

  cleanUser({ commit }) {
    commit("SET_USER", null);
  },

  // cleanAssets({ commit }) {
  //   commit("SET_ASSETS", []);
  //   commit("SET_ASSETS_PAGINATION", {
  //     page: 0,
  //     limit: 0,
  //     totalItems: 0,
  //     totalPages: 0,
  //   });
  // },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
