<template>
	<!-- <v-select
		v-model="country"
		v-bind:data-actions="'SelectCountry'"
		:items="countries"
		label="Seleccionar País"
		item-text="name"
		item-value="id"
		single-line
		hide-details
		solo
		class="mx-2"
		@change="
			(val) => {
				listenSelect(val);
			}
		"
	></v-select>
     -->
	<div class="text-center mr-2" v-if="company">
		<v-menu open-on-hover bottom offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn text v-bind="attrs" v-on="on" min-width="140">
					{{ company ? company.name : 'Empresa' }}
					<v-icon right> mdi-chevron-down </v-icon>
				</v-btn>
			</template>

			<v-list>
				<v-list-item
					v-for="(item, index) in companies"
					:key="index"
					@click="listenSelect(item)"
				>
					<v-list-item-title>{{ item.name }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>
<script>
export default {
	name: 'CompanySelect',
	props: {},
	data: () => ({}),
	computed: {
		companies: function () {
			return this.$store.getters['auth/getCompanies'] || [];
		},

		company: function () {
			const companies = this.$store.getters['auth/getCompanies'] || [];
			const found = companies.find(
				(company) => company.id == localStorage.getItem('company_id')
			);
			return found || null;
		},
	},
	methods: {
		listenSelect(val) {
			localStorage.setItem('company_id', val.id);
			location.reload();
		},
	},
};
</script>
