import { http } from '@/store/api/http-common';

const getSpareUnsubscribe = async (params) => {
	try {
		const { data } = await http.get('spare-unsubscribe', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getSpareUnsubscribeById = async (id) => {
	try {
		const { data } = await http.get(`spare-unsubscribe/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export { getSpareUnsubscribe, getSpareUnsubscribeById };
