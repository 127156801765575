import { http } from '@/store/api/http-common';

const saveAssetAssignment = async (params) => {
    try {
        const { data } = await http.post('asset-assignments/assign-assets', params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const saveUserSigner = async (params) => {
    try {
        const { data } = await http.post('asset-assignments/add-signer', params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const saveUserSignerReturn = async (params) => {
    try {
        const { data } = await http.post('asset-assignments/add-signer-return', params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getAssetAssignmentById = async (id) => {
    try {
        const { data } = await http.get(`asset-assignments/${id}`);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getAssetAssignmentReturnById = async (id) =>{
    try {
        const { data } = await http.get(`asset-assignments/assignment-return/${id}`);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getAssetAssignmentCertificateById = async (id, userTimeZone) => {
    try {
        const response = await http.patch(`asset-assignments/download-certificate/${id}`, { userTimeZone }, {
            responseType: 'blob'
        });
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getCertificateReturnAssetAssignmentById = async (id, userTimeZone) => {
    try {
        const { data } = await http.patch(`asset-assignments/return-asset/download-certificate/${id}`, { userTimeZone }, {
            responseType: 'blob'
        });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getAssetAssignments = async (params) => {
    try {
        const { data } = await http.get('asset-assignments', { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getAssetAssignmentsReturn = async (params) => {
    try {
        const { data } = await http.get('asset-assignments/assignments-return', { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const returnAssetAssignment = async ( params) => {
    try {
     
        const { data } = await http.post(`asset-assignments/return-assets`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const cancelAssetPreassignment = async (id, params) => {
    try {
        const { data } = await http.put(`asset-assignments/cancel-assignment/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const declineAssetPreassignment = async (id, params) => {
    try {
        const { data } = await http.put(`asset-assignments/decline-assignment/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const confirmAssetPreassignment = async (id, params) => {
    try {
        const { data } = await http.put(`asset-assignments/confirm-assignment/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const confirmAssetReturn = async (id, params) => {
    try {
        const { data } = await http.put(`asset-assignments/confirm-return/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const notificationManualAssetAssignment = async (id, params) => {
    try {
        const { data } = await http.put(`asset-assignments/notification-manual/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const notificationManualAssetAssignmentReturn = async (id, params) => {
    try {
        const { data } = await http.put(`asset-assignments/notification-manual-return-assignment/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

export {
    saveAssetAssignment,
    saveUserSigner,
    getAssetAssignmentById,
    getAssetAssignmentCertificateById,
    getCertificateReturnAssetAssignmentById,
    getAssetAssignments,
    returnAssetAssignment,
    cancelAssetPreassignment,
    declineAssetPreassignment,
    confirmAssetPreassignment,
    confirmAssetReturn,
    notificationManualAssetAssignment,
    getAssetAssignmentReturnById,
    getAssetAssignmentsReturn,
    notificationManualAssetAssignmentReturn,
    saveUserSignerReturn
}