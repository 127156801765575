import * as incidentService from './service/incident.service.js';
const state = {
	incident: null,
};

const mutations = {
	SET_INCIDENT(state, data) {
		state.incident = data;
	},
};

const actions = {
	async createIncident(__, data) {
		try {
			const response = await incidentService.createIncident(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	// cleanUser({ commit }) {
	// 	commit('SET_USER', null);
	// },
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
