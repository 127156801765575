import { http } from '@/store/api/http-common';

const saveTicketAttention = async (params) => {
    try {
        const { data } = await http.post('ticket-attentions', params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getTicketAttentionById = async (id) => {
    try {
        const { data } = await http.get(`ticket-attentions/${id}`);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getTicketAttention = async (params) => {
    try {
        const { data } = await http.get('ticket-attentions', { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

export {
    saveTicketAttention,
    getTicketAttention,
    getTicketAttentionById,
};