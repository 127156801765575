import * as assetHistoryService from "./service/asset-history.service";

const state = {
  assetHistory: null,
  assetsHistory: [],
  assetHistoryPagination: {
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
  },
  allAssetsHistory: [],
  loadingAssetHistory: false,
  filtersAssetHistory: {
    assetHistory_type: null,
  },
};

const mutations = {
  SET_FILTER_ASSET_HISTORY(state, data) {
    state.filtersAssetHistory = data;
  },

  SET_LOADING_ASSET_HISTORY(state, data) {
    state.loadingAssetHistory = data;
  },

  //
  SET_ASSET_HISTORY(state, asset) {
    state.assetHistory = asset;
  },
  //

  SET_ALL_ASSETS_HISTORY(state, data) {
    state.allAssetsHistory = data;
  },

  SET_ASSETS_HISTORY(state, payload) {
    state.assetsHistory = payload;
  },

  SET_ASSET_HISTORY_PAGINATION(state, payload) {
    state.assetHistoryPagination = {
      ...state.assetHistoryPagination,
      ...payload,
    };
  },

  SET_PAGE_ASSET_HISTORY(state, page) {
    state.assetsHistory.page = page;
  },

  SET_LIMIT_ASSET_HISTORY(state, limit) {
    state.assetsHistory.limit = limit;
  },

  UPDATE_ASSET_HISTORY(state, updateAssetHistory) {
    const index = state.assetsHistory.findIndex(
      (assetHistory) => assetHistory.id === updateAssetHistory.id
    );
    if (index !== -1) {
      state.assetsHistory.splice(index, 1, updateAssetHistory);
    }
  },

  DELETE_ASSET_HISTORY(state, assetHistoryId) {
    const index = state.assetsHistory.findIndex(
      (assetHistory) => assetHistory.id === assetHistoryId
    );
    if (index !== -1) {
      state.assetsHistory.splice(index, 1);
    }
  },
};

const actions = {
  async assignUser(__, data) {
    try {
      const response = await assetHistoryService.assignUser(data);
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },

  async listAssetHistory({ commit }, data) {
    try {
      commit("SET_ASSETS_HISTORY", []);
      const response = await assetHistoryService.getAssetHistory(data);
      const pagination = {
        page: response?.assetHistoryPagination.page,
        limit: response?.assetHistoryPagination.limit,
        totalItems: response?.assetHistoryPagination.totalItems,
        totalPages: response?.assetHistoryPagination.totalPages,
      };
      commit("SET_ASSETS_HISTORY", response?.assetHistory || []);
      commit("SET_ASSET_HISTORY_PAGINATION", pagination || {});
      return { ok: true, response };
    } catch (error) {
      return { ok: false, error: error?.response || "Error en el servicio" };
    }
  },
  cleanAssetsHistory({ commit }) {
    commit("SET_ASSETS_HISTORY", []);
    commit("SET_ASSET_HISTORY_PAGINATION", {
      page: 0,
      limit: 0,
      totalItems: 0,
      totalPages: 0,
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
