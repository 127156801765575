import * as ticketAttentionService from './service/ticket-attention.service';

const state = {
    ticketAttention: null,
    ticketAttentions: [],
    ticketAttentionsPagination: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE,
        totalItems: 0,
        totalPages: 0,
    },
    allTicketAttentions: [],
    loadingTicketAttention: false,
    filtersTicketAttention: {
        ticket_number: null
    }
};

const mutations = {
    SET_FILTER_TICKET_ATTENTION(state, data) {
        state.filtersTicketAttention = data;
    },

    SET_LOADING_TICKET_ATTENTION(state, data) {
        state.loadingTicketAttention = data;
    },

    SET_TICKET_ATTENTION(state, ticketAttention) {
        state.ticketAttention = ticketAttention;
    },

    SET_ALL_TICKET_ATTENTIONS(state, data) {
        state.allTicketAttentions = data;
    },

    SET_TICKET_ATTENTIONS(state, payload) {
        state.ticketAttentions = payload;
    },

    SET_TICKET_ATTENTION_PAGINATION(state, payload) {
        state.ticketAttentionsPagination = { ...state.ticketAttentionsPagination, ...payload };
    },

    SET_PAGE(state, page) {
        state.ticketAttentions.page = page;
    },

    SET_LIMIT(state, limit) {
        state.ticketAttentions.limit = limit;
    },

    // UPDATE_TICKET_ATTENTION(state, updateTicketAttention) {
    //     const index = state.ticketAttentions.findIndex((ticketAttention) => ticketAttention.id === updateTicketAttention.id);
    //     if (index !== -1) {
    //         state.ticketAttentions.splice(index, 1, updateTicketAttention);
    //     }
    // },

    // DELETE_TICKET_ATTENTION(state, ticketAttentionId) {
    //     const index = state.ticketAttentions.findIndex((ticketAttention) => ticketAttention.id === ticketAttentionId);
    //     if (index !== -1) {
    //         state.ticketAttentions.splice(index, 1);
    //     }
    // },
};

const actions = {
    async createTicketAttention(__, data) {
        try {
            const response = await ticketAttentionService.saveTicketAttention(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async getTicketAttentionById({ commit }, id) {
        try {
            const response = await ticketAttentionService.getTicketAttentionById(id);
            commit('SET_TICKET_ATTENTION', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listAllTicketAttentions({ commit }, data) {
        try {
            const response = await ticketAttentionService.getTicketAttention(data);
            commit('SET_ALL_TICKET_ATTENTIONS', response.ticketAttentions);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listTicketAttention({ commit }, data) {
        try {
            commit('SET_TICKET_ATTENTIONS', []);
            const response = await ticketAttentionService.getTicketAttention(data);
            const pagination = {
                page: response?.ticketAttentionsPagination.page,
                limit: response?.ticketAttentionsPagination.limit,
                totalItems: response?.ticketAttentionsPagination.totalItems,
                totalPages: response?.ticketAttentionsPagination.totalPages,
            };
            commit('SET_TICKET_ATTENTIONS', response?.ticketAttentions || []);
            commit('SET_TICKET_ATTENTION_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    // async updateTicketAttention({ commit }, { id, data }) {
    //     try {
    //         const response = await ticketAttentionService.updateTicketAttention(id, data);
    //         commit('UPDATE_TICKET_ATTENTION', response);
    //         return { ok: true, response };
    //     } catch (error) {
    //         return { ok: false, error: error?.response || 'Error en el servicio' };
    //     }
    // },

    // async deleteTicketAttention(__, id) {
    //     try {
    //         const response = await ticketAttentionService.deleteTicketAttention(id);
    //         return { ok: true, response };
    //     } catch (error) {
    //         return { ok: false, error: error?.response || 'Error en el servicio' };
    //     }
    // },

    // cleanUser({ commit }) {
    //     commit('SET_USER', null);
    // },

    cleanTicketAttentions({ commit }) {
        commit('SET_TICKET_ATTENTIONS', []);
        commit('SET_TICKET_ATTENTION_PAGINATION', {
            page: 0,
            limit: 0,
            totalItems: 0,
            totalPages: 0,
        });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};