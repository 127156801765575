import * as areaService from './service/area.service';
const state = {
	area: null,
	areas: [],
	areasPagination: {
		page: 1,
		limit: 10,
		totalItems: 0,
		totalPages: 0,
	},
	allAreas: [],
	//
	allAreasSubtype:[],
	areaSubtypes:[],
	areasSubtypePagination: {
		page: 1,
		limit: 10,
		totalItems: 0,
		totalPages: 0,
	},
};

const mutations = {
	SET_ALL_AREAS(state, data) {
		state.allAreas = data;
	},

	SET_AREAS(state, payload) {
		state.areas = payload;
	},

	SET_AREAS_PAGINATION(state, payload) {
		state.areasPagination = { ...state.areasPagination, ...payload };
	},
	
	//
	SET_ALL_AREAS_SUBTYPE(state, data) {
		state.allAreasSubtype = data;
	},

	SET_AREAS_SUBTYPE(state, payload) {
		state.areaSubtypes = payload;
	},

	SET_AREAS_SUBTYPE_PAGINATION(state, payload) {
		state.areasSubtypePagination = { ...state.areasSubtypePagination, ...payload };
	},
	//

	SET_PAGE(state, page) {
		state.areas.page = page;
	},

	SET_LIMIT(state, limit) {
		state.areas.limit = limit;
	},

	/*x confirmar */
	UPDATE_AREA(state, updatedArea) {
		const index = state.areas.findIndex((area) => area.id === updatedArea.id);
		if (index !== -1) {
			state.areas.splice(index, 1, updatedArea);
		}
	},
	DELETE_AREA(state, areaId) {
		const index = state.areas.findIndex((area) => area.id === areaId);
		if (index !== -1) {
			state.areas.splice(index, 1);
		}
	},
	/** */
};

const actions = {
	async createArea({ state }, data) {
		try {
			const existingArea = state.areas.find(
				(area) => area.name === data.name && area.company_id === data.company_id
			);
			if (existingArea) {
				return {
					ok: false,
					error: 'Esta área ya existe para la empresa seleccionada',
				};
			}
			const response = await areaService.saveArea(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async createAreaMultiple(__, data){
		try{
			const response = await areaService.saveAreaMultiple(data);
			return { ok: true, response };
		}catch(error){
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async downloadAreaFormat() {
		try {
			await areaService.downloadAreaFormat();
			return { ok: true };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listAllAreas({ commit }, data) {
		try {
			const response = await areaService.getArea(data);
			commit('SET_ALL_AREAS', response.data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listArea({ commit }, data) {
		try {
			commit('SET_AREAS', []);
			const response = await areaService.getArea(data);
			const pagination = {
				page: response?.page,
				limit: response?.limit,
				totalItems: response?.totalItems,
				totalPages: response?.totalPages,
			};
			commit('SET_AREAS', response?.data || []);
			commit('SET_AREAS_PAGINATION', pagination || {});
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	//
	async listAllAreasSubtype({ commit }, data) {
		try {
			const response = await areaService.getAreaSubtypes(data);
			commit('SET_ALL_AREAS_SUBTYPE', response.data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listAreaSubtype({ commit }, data) {
		try {
			// commit('SET_AREAS_SUBTYPE', []);
			const response = await areaService.getAreaSubtypes(data);
			const pagination = {
				page: response?.page,
				limit: response?.limit,
				totalItems: response?.totalItems,
				totalPages: response?.totalPages,
			};
			commit('SET_AREAS_SUBTYPE', response?.data || []);
			commit('SET_AREAS_SUBTYPE_PAGINATION', pagination || {});
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},
	//

	async updateArea({ state, commit }, { id, data }) {
		try {
			const existingArea = state.areas.find(
				(area) => area.name === data.name && area.company_id === data.company_id
			);
			if (existingArea && existingArea.id !== id) {
				return {
					ok: false,
					error: 'Esta área ya existe para la empresa seleccionada',
				};
			}

			delete data['company_id'];

			const response = await areaService.updateArea(id, data);

			commit('UPDATE_AREA', response);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async deleteArea(__, id) {
		try {
			const response = await areaService.deleteArea(id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},
	/**/

	cleanUser({ commit }) {
		commit('SET_USER', null);
	},

	cleanAreas({ commit }) {
		commit('SET_AREAS', []);
		commit('SET_ALL_AREAS', []);
		commit('SET_AREAS_PAGINATION', {
			page: 0,
			limit: 0,
			totalItems: 0,
			totalPages: 0,
		});
	},

	cleanAreasSubtype({ commit }) {
		commit('SET_AREAS_SUBTYPE', []);
		commit('SET_ALL_AREAS_SUBTYPE', []);
		commit('SET_AREAS_SUBTYPE_PAGINATION', {
			page: 0,
			limit: 0,
			totalItems: 0,
			totalPages: 0,
		});
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
