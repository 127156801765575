import { http } from "@/store/api/http-common";

const getTransfers = async (params) => {
    try {
        const { data } = await http.get("transfers", { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const getTransferById = async (id) => {
    try {
        const { data } = await http.get(`transfers/${id}`);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const saveTransfer = async (params) => {
    try {
        const { data } = await http.post("transfers", params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const confirmTransfer = async (params) => {
    try {
        const { data } = await http.post("transfers/confirm-transfer", params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const cancelTransfer = async (id, params) => {
    try {
        const { data } = await http.put(`transfers/cancel-transfer/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}

const rejectTransfer = async (id, params) => {
    try {
        const { data } = await http.put(`transfers/reject-transfer/${id}`, params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
}
/** */

export { getTransfers, getTransferById, saveTransfer, confirmTransfer, cancelTransfer, rejectTransfer };