import * as ratingService from './service/rating.service';
const state = {
	ratings: [],
	ratingsPagination: {
		page: 1,
		limit: 10,
		totalItems: 0,
		totalPages: 0,
	},
};

const mutations = {
	SET_RATINGS(state, payload) {
		state.ratings = payload;
	},

	SET_RATINGS_PAGINATION(state, payload) {
		state.ratingsPagination = { ...state.ratingsPagination, ...payload };
	},
};

const actions = {
	async getPendingRatingByTicket(_, params) {
		try {
			const response = await ratingService.getPendingRatingByTicket(params);
			return { ok: true, response: response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getRatings({ commit }, params) {
		try {
			const response = await ratingService.getRatings(params);
			commit('SET_RATINGS', response);
			return { ok: true, response: response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},
	async createRatings(_, payload) {
		try {
			const response = await ratingService.createRatings(payload);
			return { ok: true, response: response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	cleanRatings({ commit }) {
		commit('SET_RATINGS', []);
		commit('SET_RATINGS_PAGINATION', {
			page: 0,
			limit: 0,
			totalItems: 0,
			totalPages: 0,
		});
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
