import { http } from '@/store/api/http-common';

const saveSpare = async (params) => {
	try {
		const { data } = await http.post('spares', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveSpareMultiple = async (params) => {
	try {
		const { data } = await http.post('spares/multiple-create', params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};


const getSpareById = async (id) => {
	try {
		const { data } = await http.get(`spares/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const downloadSpareFormat = async () => {
    try {
        const response = await http.get('spares/format/download', { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'FormatDeCargaMasivaRepuesto.xlsx');
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        return Promise.reject(error);
    }
};
//

const getSpare = async (params) => {
	try {
		const { data } = await http.get('spares', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateSpare = async (id, params) => {
	try {
		const { data } = await http.put(`spares/${id}`, params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const cancelSpare = async (id, params) => {
	try {
		const { data } = await http.put(`spares/unsubscribe-spare/${id}`, params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const reestablishSpare = async (id) => {
	try {
		const { data } = await http.put(`spares/reestablish-spare/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteSpare = async (id) => {
	try {
		const { data } = await http.delete(`spares/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getExportSpare = async (params) => {
	try {
		// const { data } = await http.get('assets', { params });
		// return data || null;

		const response = await http.get('spares/export', { params, responseType: 'blob' });
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'Reporte_repuestos.xlsx');
		document.body.appendChild(link);
		link.click();

	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	saveSpare,
    getSpareById,
    getSpare,
    updateSpare,
    deleteSpare,
	saveSpareMultiple,
	cancelSpare,
	reestablishSpare,
	downloadSpareFormat,
	getExportSpare
};
