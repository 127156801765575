<template>
	<v-snackbar v-model="snackbar" :color="status" v-bind="variant">
		<div v-html="text"></div>
		<template v-slot:action="{ attrs }">
			<v-btn
				text
				v-bind="attrs"
				:data-s="JSON.stringify(attrs)"
				@click="snackbar = false"
			>
				Cerrar
			</v-btn>
		</template>
	</v-snackbar>
</template>
<script>
import { $EventBus } from '@/main';
export default {
	data: () => ({
		snackbar: false,
		text: ``,
		status: '',
		variant: {},
	}),
	mounted: function () {
		$EventBus.$on('showSnack', (status, message, variant = {}) => {
			this.snackbar = true;
			this.status = status;
			this.text = message;
			this.variant = variant;
		});
	},
};
</script>
