import { http } from "@/store/api/http-common";

const assignUser = async (params) => {
  try {
    const { data } = await http.post("asset-history/assign-user", params);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getAssetHistory = async (params) => {
  try {
    const { data } = await http.get("asset-history", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { assignUser, getAssetHistory };
