import { http } from '@/store/api/http-common';

const saveSpareAssign = async (params) => {
    try {
        const { data } = await http.post('spare-assign/assign-asset-spare', params);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};


const getSpareAssignById = async (id) => {
    try {
        const { data } = await http.get(`spare-assign/${id}`);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const getSpareAssign = async (params) => {
    try {
        const { data } = await http.get('spare-assign', { params });
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

export {
    saveSpareAssign,
    getSpareAssignById,
    getSpareAssign
};
