import * as assetAssignmentService from './service/asset-assignment.service';

const state = {
    assetAssignment: null,
    assetAssignmentReturn: null,
    assetsAssignment: [],
    assetAssignmentPagination: {
        page: 1,
        limit: 10,
        totalItems: 0,
        totaPages: 0,
    },
    allAssetsAssignment: [],
    loadingAssetAssignment: false,
    assetAssignmentCertificate: null,
    //Asset assignment return
    allassetsAssignmentReturn: [],
    assetsAssignmentReturn: [],
    assetAssignmentReturnPagination: {
        page: 1,
        limit: 10,
        totalItems: 0,
        totaPages: 0,
    },
}

const mutations = {
    SET_ASSET_ASSIGNMENT(state, assetAssignment) {
        state.assetAssignment = assetAssignment;
    },

    SET_ASSET_ASSIGNMENT_RETURN(state, assetAssignmentReturn) {
        state.assetAssignmentReturn = assetAssignmentReturn;
    },

    SET_ALL_ASSETS_ASSIGNMENT(state, data) {
        state.allAssetsAssignment = data;
    },

    SET_ASSETS_ASSIGNMENT(state, payload) {
        state.assetsAssignment = payload;
    },

    SET_ASSET_ASSIGNMENT_PAGINATION(state, payload) {
        state.assetAssignmentPagination = {
            ...state.assetAssignmentPagination,
            ...payload,
        };
    },

    UPDATE_ASSET_ASSIGNMENT(state, updateAssetAssignment) {
        const index = state.assetsAssignment.findIndex((assetAssign) => assetAssign.id === updateAssetAssignment.id);
        if (index !== -1) {
            state.assetsAssignment.splice(index, 1, updateAssetAssignment);
        }
    },

    //asset assignment return
    SET_ALL_ASSETS_ASSIGNMENT_RETURN(state, data) {
        state.allassetsAssignmentReturn = data;
    },

    SET_ASSETS_ASSIGNMENT_RETURN(state, payload) {
        state.assetsAssignmentReturn = payload;
    },

    SET_ASSET_ASSIGNMENT_RETURN_PAGINATION(state, payload) {
        state.assetAssignmentReturnPagination = {
            ...state.assetAssignmentReturnPagination,
            ...payload,
        };
    },

}

const actions = {
    async createAssetAssignment(__, { data }) {
        try {
            const response = await assetAssignmentService.saveAssetAssignment(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    async getAssignmentById({ commit }, id) {
        try {
            const response = await assetAssignmentService.getAssetAssignmentById(id);
            commit('SET_ASSET_ASSIGNMENT', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async getAssignmentReturn({ commit }, id) {
        try {
            const response = await assetAssignmentService.getAssetAssignmentReturnById(id);
            commit('SET_ASSET_ASSIGNMENT_RETURN', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async getAssetAssignmentCertificateById(_, { id, data }) {
        try {
            const response = await assetAssignmentService.getAssetAssignmentCertificateById(id, data);
            // commit('SET_ASSET_ASSIGN_CERTIFICATE', response); // Esta línea parece no ser necesaria
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async getAssetAssignmentReturnCertificate(_, { id, data }) {
        try {
            const response = await assetAssignmentService.getCertificateReturnAssetAssignmentById(id, data);
            // commit('SET_ASSET_ASSIGN_CERTIFICATE', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listAllAssetAssign({ commit }, data) {
        try {
            const response = await assetAssignmentService.getAssetAssignments(data);
            commit('SET_ALL_ASSETS_ASSIGNMENT', response.data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listPaginationAssetAssignment({ commit }, data) {
        try {
            commit('SET_ALL_ASSETS_ASSIGNMENT', []);
            const response = await assetAssignmentService.getAssetAssignments(data);
            const pagination = {
                page: response?.pagination.page,
                limit: response?.pagination.limit,
                totalItems: response?.pagination.totalItems,
                totalPages: response?.pagination.totalPages,
            };
            commit('SET_ALL_ASSETS_ASSIGNMENT', response?.data || []);
            commit('SET_ASSET_ASSIGNMENT_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listAllAssetAssignmentReturn({ commit }, data) {
        try {
            const response = await assetAssignmentService.getAssetAssignmentsReturn(data);
            commit('SET_ALL_ASSETS_ASSIGNMENT_RETURN', response.data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listPaginationAssetAssignmentReturn({ commit }, data) {
        try {
            commit('SET_ALL_ASSETS_ASSIGNMENT_RETURN', []);
            const response = await assetAssignmentService.getAssetAssignmentsReturn(data);
            const pagination = {
                page: response?.pagination.page,
                limit: response?.pagination.limit,
                totalItems: response?.pagination.totalItems,
                totalPages: response?.pagination.totalPages,
            };
            commit('SET_ALL_ASSETS_ASSIGNMENT_RETURN', response?.data || []);
            commit('SET_ASSET_ASSIGNMENT_RETURN_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async cancelPreAssignmentAsset({ commit }, { id, data }) {
        try {
            const response = await assetAssignmentService.cancelAssetPreassignment(id, data);
            commit('UPDATE_ASSET_ASSIGNMENT', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async declinePreassignmentAsset({ commit }, { id, data }) {
        try {
            const response = await assetAssignmentService.declineAssetPreassignment(id, data);
            commit('UPDATE_ASSET_ASSIGNMENT', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async confirmPreassignmentAsset({ commit }, { id, data }) {
        try {
            const response = await assetAssignmentService.confirmAssetPreassignment(id, data);
            commit('UPDATE_ASSET_ASSIGNMENT', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async confirmReturnAsset(__, { id, data }) {
        try {
            const response = await assetAssignmentService.confirmAssetReturn(id, data);
            //commit('UPDATE_ASSET_ASSIGN', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async assetReturnAssignment(__, { data }) {
        try {
            const response = await assetAssignmentService.returnAssetAssignment(data);
            //commit('UPDATE_ASSET_ASSIGN', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async notificationAssetAssignmentManual(__, { id, data }) {
        try {
            const response = await assetAssignmentService.notificationManualAssetAssignment(id, data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async notificationAssetAssignmentReturnManual(__, { id, data }) {
        try {
            const response = await assetAssignmentService.notificationManualAssetAssignmentReturn(id, data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async addSignerUserAssetAssignment(__, { data }) {
        try {
            const response = await assetAssignmentService.saveUserSigner(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    async addSignerUserAssetAssignmentReturn(__, { data }) {
        try {
            const response = await assetAssignmentService.saveUserSignerReturn(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    cleanAssetAssignment({ commit }) {
        commit('SET_ASSET_ASSIGNMENT', null);
    },

    cleanAssetsAssign({ commit }) {
        commit('SET_ASSETS_ASSIGNMENT', []);
        commit('SET_ASSET_ASSIGNMENT_PAGINATION', {
            page: 0,
            limit: 0,
            totalItems: 0,
            totalPages: 0,
        });
    },
}

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}