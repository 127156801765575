import * as transferService from './service/transfer.service';

const state = {
    transfer: null,
    transfers: [],
    transfersPagination: {
        page: 1,
        limit: 10,
        totalItems: 0,
        totalPages: 0,
    },
    allTransfers: [],
    loadingTransfer: false,
    filtersTransfer: {
        status: null,
        origin_service: null,
        destination_service: null
    },
};

const mutations = {
    SET_FILTER_TRANSFER(state, data) {
        state.filtersTransfer = data;
    },

    SET_LOADING_TRANSFER(state, data) {
        state.loadingTransfer = data;
    },

    //
    SET_TRANSFER(state, transfer) {
        state.transfer = transfer;
    },
    //

    SET_ALL_TRANSFERS(state, data) {
        state.allTransfers = data;
    },

    SET_TRANSFERS(state, payload) {
        state.transfers = payload;
    },

    SET_TRANSFER_PAGINATION(state, payload) {
        state.transfersPagination = { ...state.transfersPagination, ...payload };
    },

    SET_PAGE(state, page) {
        state.transfers.page = page;
    },

    SET_LIMIT(state, limit) {
        state.transfers.limit = limit;
    },

    UPDATE_TRANSFER(state, updateTransfer) {
        const index = state.transfers.findIndex((asset) => asset.id === updateTransfer.id);
        if (index !== -1) {
            state.transfers.splice(index, 1, updateTransfer);
        }
    },

    // DELETE_ASSET(state, assetId) {
    // 	const index = state.assets.findIndex((asset) => asset.id === assetId);
    // 	if (index !== -1) {
    // 		state.assets.splice(index, 1);
    // 	}
    // },
};

const actions = {
    async createTransfer(__, data) {
        try {
            const response = await transferService.saveTransfer(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async getTransferById({ commit }, id) {
        try {
            const response = await transferService.getTransferById(id);
            commit('SET_TRANSFER', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listAllTransfers({ commit }, data) {
        try {
            const response = await transferService.getTransfers(data);
            commit('SET_ALL_TRANSFERS', response.transfers);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async listTransfer({ commit }, data) {
        try {
            commit('SET_TRANSFERS', []);
            const response = await transferService.getTransfers(data);
            const pagination = {
                page: response?.transfersPagination.page,
                limit: response?.transfersPagination.limit,
                totalItems: response?.transfersPagination.totalItems,
                totalPages: response?.transfersPagination.totalPages,
            };
            commit('SET_TRANSFERS', response?.transfers || []);
            commit('SET_TRANSFER_PAGINATION', pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async confirmTransfer({ commit }, { id, data }) {
        try {
            const response = await transferService.confirmTransfer(id, data);
            commit('UPDATE_TRANSFER', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async rejectTransfer({ commit }, { id, data }) {
        try {
            const response = await transferService.rejectTransfer(id, data);
            commit('UPDATE_TRANSFER', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    async cancelTransfer({ commit }, id) {
        try {
            const response = await transferService.cancelTransfer(id);
            commit('UPDATE_TRANSFER', response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

    cleanUser({ commit }) {
        commit('SET_USER', null);
    },

    cleanTransfers({ commit }) {
        commit('SET_TRANSFER', []);
        commit('SET_TRANSFER_PAGINATION', {
            page: 0,
            limit: 0,
            totalItems: 0,
            totalPages: 0,
        });
    },
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
