import { http } from "@/store/api/http-common";

const saveRatingType = async (params) => {
  try {
    const { data } = await http.post("ratings", params);
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getRatingType = async (params) => {
  try {
    const { data } = await http.get("ratings", { params });
    return data || null;
  } catch (error) {
    return Promise.reject(error);
  }
};

// const updateRatingType = async (id,params) => {
//   try {
//     const { data } = await http.put(`Rating-types/${id}`, params);
//     return data || null;
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };

// const deleteRatingType = async (id) => {
//   try {
//     const { data } = await http.delete(`Rating-types/${id}`);
//     return data || null;
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };

export { saveRatingType, getRatingType};
